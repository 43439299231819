<template>
  <b-tabs id="tab-container" pills content-class="mt-3" align="center">
    <b-tab title="Pojazdy we flocie" active>
      <b-table
        v-if="returnCars"
        hover
        striped
        class="vehtable"
        :items="returnCars"
        :sort-by.sync="sortBy"
        :fields="fields"
        responsive
      >
      <template #cell(edycja)="row">
        <b-button size="sm" @click="edit(row.item)" class="mr-1">
          Edytuj
        </b-button>
      </template>

      
      </b-table>
      <h4 v-if="!returnCars">Nie dodałeś jeszcze pojazdów, dodaj coś.</h4>
    </b-tab>
    <new-car />
  </b-tabs>
</template>

<script>
import NewCar from "../components/vehicles/NewCar.vue";
import EditCar from '../components/vehicles/EditCar.vue';
export default {
  components: { NewCar, EditCar },
  data() {
    return {
      isLoading: false,
      sortBy: 'reg',
      fields: [
        {
          key: "reg",
          label: "Nr rej.",
          sortable: true,
        },
        {
          key: "make",
          label: "Marka",
          sortable: true,
        },
        {
          key: "model",
          label: "Model",
          sortable: true,
        },
        {
          key: "vin",
          label: "VIN",
        },
        {
          key: "service",
          label: "Kolejny przegląd",
          sortable: true,
        },
        {
          key: "entryDate",
          label: "Data pierwszej rejestracji",
          sortable: true,
        },
        {
          key: "priority",
          label: "Piorytet",
          sortable: true,
        },
        {
          key: "sort",
          label: "Kolejność",
          sortable: true,
        },
        {
          key: "edycja",
          label: 'Edycja'
        }
      ],
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.isLoading = true;
      await this.$store.dispatch("getCars");
      this.isLoading = false;
    },
    async edit(val){
      this.$router.push({path: `/admin/samochody/edycja/${val.id}`})
    }
  },
  computed: {
    returnCars() {
      return !this.isLoading && this.$store.getters["returnCars"];
    },
  },
};
</script>

<style scoped>
#tab-container {
  width: 95%;
  background-color: #fff;
  border-radius: 20px;
  padding: 20px;
}
.vehtable {
  text-align: center;
}
</style>
