<template>
    <b-tabs id="tab-container" pills content-class="mt-3" align="center">
    <b-tab title="Średnia zmian" active>
        <div class="infobox" v-if="getWeekNumbers && !startSmaller">
<h4>Wybrany okres</h4>
Start okresu: {{getWeekNumbers.startingWeek}} tydzień - Koniec okresu: {{getWeekNumbers.endingWeek}} tydzień
        </div>
        <b-alert :show="startSmaller" variant="danger">Tydzień startowy musi być niższy niż koniec okresu.</b-alert>
        <b-form-group invalid-feedback="Możesz pobrać okres maksymalnie od bieżącego tygodnia." :state="correctStartPeriod" label="Wprowadź start okresu" description="Niezależnie od wybranego dnia, tydzień liczony jest od poniedziałku.">
            <b-form-input v-model="shiftAverage.start" type="date"/>
        </b-form-group>
        <b-form-group invalid-feedback="Możesz pobrać okres maksymalnie do bieżącego tygodnia." :state="correctEndPeriod" label="Wprowadź koniec okresu" description="Niezależnie od wybranego dnia, tydzień liczony jest do niedzieli.">
            <b-form-input v-model="shiftAverage.end" type="date"/>
        </b-form-group>
        <b-form-group label="Adres e-mail" description="Wprowadź adres e-mail">
            <b-form-input v-model.trim="shiftAverage.email" type="email"/>
        </b-form-group>
        <b-button @click="sendAvgForm" class="float-right" variant="primary">Wyślij raport</b-button>
    </b-tab>
  </b-tabs>
</template>


<script>
export default {
    data(){
        return {
            shiftAverage: {
                start: null,
                end: null,
                email: null,
            }
        }
    },
    computed: {
        getWeekNumbers(){
            if(this.shiftAverage.start && this.shiftAverage.end){
                let startingWeek = this.$dayjs(this.shiftAverage.start).week()
                let endingWeek = this.$dayjs(this.shiftAverage.end).week()
                let endY = this.$dayjs(this.shiftAverage.end).year()
                let startY = this.$dayjs(this.shiftAverage.start).year()
                return {startingWeek, endingWeek, endY, startY}
            }
            return null
        },
        currentWeek(){
            return this.$dayjs().week()
        },
        correctEndPeriod(){
            if(this.getWeekNumbers && this.getWeekNumbers.endingWeek && this.getWeekNumbers.endingWeek > this.currentWeek){
                return false
            } else return null;
        },
        correctStartPeriod(){
            if(this.getWeekNumbers && this.getWeekNumbers.startingWeek && this.getWeekNumbers.startingWeek > this.currentWeek){
                return false
            } else return null;
        },
        startSmaller(){
            return this.getWeekNumbers && this.getWeekNumbers.startingWeek > this.getWeekNumbers.endingWeek;
        }
    },
    methods: {
        sendAvgForm(){
            const week = this.getWeekNumbers.startingWeek
            const endWeek = this.getWeekNumbers.endingWeek
            const mail = this.shiftAverage.email
            const year = this.getWeekNumbers.startY
            if(this.getWeekNumbers && this.shiftAverage.email && this.getWeekNumbers.startingWeek === this.getWeekNumbers.endingWeek){
                // send single
      
                this.$store.dispatch('getAvgSingleWeek', {week, mail, year })
            }
            if(this.getWeekNumbers && this.shiftAverage.email && this.getWeekNumbers.startingWeek !== this.getWeekNumbers.endingWeek){
                // send period
                this.$store.dispatch('getAvgPeriod', {week, endWeek, mail, year})
            }
        }
    }
}
</script>



<style scoped>
#tab-container {
  width: 95%;
  background-color: #fff;
  border-radius: 20px;
  padding: 20px;
}
.vehtable {
  text-align: center;
}
.infobox{
    background-color: #3a3a3a;
    border-radius: 15px;
    color: #fff;
    padding: 20px;
}
</style>