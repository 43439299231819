<template>
  <b-tabs content-class="mt-3 w-100" style="width: 100%">
    <b-tab title="Dane pojazdu">
      <b-alert variant="danger" dismissible v-model="notValid"
        >Wprowadziłeś niekompletne dane - przed kolejną próbą zweryfikuj
        poprawność danych.</b-alert
      >
      <div class="d-flex justify-content-between">
        <div class="d-flex">
          <h4>Edycja danych pojazdu</h4>
          <b-button
            variant="danger"
            @click="deleteCar(getSingleCar.id)"
            class="ml-3"
            >Usuń pojazd</b-button
          >
        </div>
        <div class="header-back" @click="goBack">
          Powrót
          <b-icon icon="backspace" />
        </div>
      </div>
      <b-row class="mb-4 space">
        <b-col cols="6" lg="4" md="6" sm="12">
          <div role="group">
            <label for="reg">Numer rejetracyjny:</label>
            <b-input id="reg" debounce="1500" trim v-model="getSingleCar.reg" />
          </div>
        </b-col>
        <b-col cols="6" lg="4" md="6" sm="12">
          <div role="group">
            <label for="marka">Marka:</label>
            <b-form-select
              id="marka"
              v-model="getSingleCar.make"
              :options="makes"
            />
          </div>
        </b-col>
        <b-col cols="6" lg="4" md="6" sm="12">
          <div role="group">
            <label for="model">Model:</label>
            <b-form-select
              id="model"
              v-model="getSingleCar.model"
              :options="returnModels"
            />
          </div>
        </b-col>
        <b-col cols="6" lg="4" md="6" sm="12">
          <div role="group">
            <label for="year">Rok produkcji:</label>
            <b-form-select
              id="year"
              v-model="getSingleCar.year"
              :options="returnYears"
            />
          </div>
        </b-col>
        <b-col cols="6" lg="4" md="6" sm="12">
          <div role="group">
            <label for="year">Kolor:</label>
            <b-form-select
              id="year"
              v-model="getSingleCar.color"
              :options="color"
            />
          </div>
        </b-col>
        <b-col cols="6" lg="4" md="6" sm="12">
          <div role="group">
            <label for="vin">Numer VIN:</label>
            <b-input trim id="vin" debounce="1500" v-model="getSingleCar.vin" />
          </div>
        </b-col>
        <b-col cols="6" lg="4" md="6" sm="12">
          <div role="group">
            <label for="entryDate">Data pierwszej rejestracji</label>
            <b-input
              type="date"
              id="entryDate"
              v-model="getSingleCar.entryDate"
            />
          </div>
        </b-col>
        <b-col cols="6" lg="4" md="6" sm="12">
          <div role="group">
            <label for="service">Następny przegląd okresowy:</label>
            <b-input
              type="date"
              id="entryDate"
              v-model="getSingleCar.service"
            />
          </div>
        </b-col>
        <b-col cols="6" lg="4" md="6" sm="12">
          <div role="group">
            <label for="exitDate">Wyjście z floty</label>
            <b-input
              type="date"
              id="exitDate"
              v-model="getSingleCar.exitDate"
            />
          </div>
        </b-col>
        <b-col cols="6" lg="4" md="6" sm="12">
          <div role="group">
            <label for="priority">Piorytet</label>
            <b-form-spinbutton
              min="0"
              max="10"
              type="date"
              id="priority"
              v-model="getSingleCar.priority"
            />
          </div>
        </b-col>
        <b-col cols="6" lg="4" md="6" sm="12">
          <div role="group">
            <label for="flotman">Numer urządzenia GPS</label>
            <b-input
              id="flotman"
              v-model="getSingleCar.flotmanId"
            />
          </div>
        </b-col>
              <b-col cols="6" lg="4" md="6" sm="12">
        <div role="group">
          <label for="isRental">Auto dostępne w wynajmie</label>
          <b-checkbox id="isRental" switch v-model="getSingleCar.isRental" />
        </div>
      </b-col>
        <b-col cols="6" lg="4" md="6" sm="12">
          <div role="group">
            <label for="priority">Kolejność</label>
            <b-form-spinbutton
              min="1"
              max="9999"
              type="date"
              id="priority"
              v-model="getSingleCar.sort"
            />
          </div>
        </b-col>

        <b-col cols="6" lg="4" md="6" sm="12">
          <div role="group">
            <label for="checkbox">Pojazd niedostępny</label>
            <b-form-checkbox
              id="checkbox"
              switch
              size="lg"
              class="ml-4"
              v-model="getSingleCar.grounded"
            />
          </div>
        </b-col>
      </b-row>
      <h4>Ubezpieczenie</h4>
      <b-row>
        <b-col cols="6" lg="4" md="6" sm="12">
          <div role="group">
            <label for="insName">Nazwa zakładu ubezpieczeń:</label>
            <b-form-select
              type="insName"
              id="entryDate"
              v-model="getSingleCar.insName"
              :options="insCompany"
            />
          </div>
        </b-col>
        <b-col cols="6" lg="4" md="6" sm="12">
          <div role="group">
            <label for="insNumber">Numer polisy:</label>
            <b-input trim id="insNumber" v-model="getSingleCar.insNum" />
          </div>
        </b-col>
        <b-col cols="6" lg="4" md="6" sm="12">
          <div role="group">
            <label for="insEnd">Zakończenie polisy:</label>
            <b-input
              type="date"
              trim
              id="insEnd"
              v-model="getSingleCar.insEnd"
            />
          </div>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col>
          <b-button @click="sendForm" class="float-right" variant="primary"
            >Zapisz</b-button
          >
        </b-col>
      </b-row>
    </b-tab>
    <b-tab title="Przypisani kierowcy">
      <div class="d-flex">
        <h4>Przypisani kierowcy</h4>
        <b-button
          class="ml-3"
          v-b-modal.addDriver
          @click="fetchDrivers"
          variant="primary"
          >NOWY KIEROWCA</b-button
        >
      </div>
      <b-row v-if="getSingleCarDrivers">
        <b-col
          class="mt-5"
          cols="4"
          v-for="item in getSingleCarDrivers"
          :key="item.id"
        >
          <div class="driver d-flex justify-content-between align-items-center">
            {{ item.fullName }}
            <b-icon
              @click="remove(item)"
              icon="person-x-fill"
              class="remove pointer"
            />
          </div>
        </b-col>
      </b-row>
    </b-tab>
    <b-tab title="Wyłączenia pojazdu">
      <div class="d-flex">
        <h4>Wyłączenia pojazdu</h4>
        <b-button class="ml-3" v-b-modal.maitenance variant="primary"
          >NOWY OKRES</b-button
        >
      </div>
      <b-row class="mt-5 grounded-row" v-if="returnGrounded">
        <b-col
          class="d-flex justify-content-center"
          cols="6"
          v-for="item in returnGrounded"
          :key="item.id"
        >
          <div class="grounded-box d-flex flex-column align-items-center">
            <div class="grounded-reg">{{ item.attributes.carReg }}</div>
            <div>Okres wyłączenia</div>
            <div class="small">
              {{
                item.attributes.notOnStation
                  ? "auto niewyświetlane w kalendarzu"
                  : ""
              }}
            </div>
            <div class="grounded-dates mt-2 d-flex">
              <div class="left text-center">
                <div class="start">Start wyłączenia</div>
                <div class="date">{{ item.attributes.dayStart }}</div>
                <div class="shift">
                  <strong>{{ item.attributes.shiftStart }}</strong>
                </div>
              </div>
              <div class="right ml-3 text-center">
                <div class="end">Koniec wyłączenia</div>
                <div class="date">{{ item.attributes.dayEnd }}</div>
                <div class="shift">
                  <strong>{{ item.attributes.shiftEnd }}</strong>
                </div>
              </div>
            </div>
            <div class="buttons d-flex mt-2 w-100 justify-content-between">
              <b-button @click="showGroundedModal(item)" variant="warning"
                >Edytuj</b-button
              >
              <b-button @click="removeGrounded(item.id)" variant="danger"
                >Usuń</b-button
              >
            </div>
          </div>
        </b-col>
      </b-row>
    </b-tab>
    <b-tab title="Serwisy" @click="fetchServices">
      <div class="d-flex">
        <h4>Serwisy pojazdu</h4>
        <b-button class="ml-3" v-b-modal.service variant="primary"
          >NOWY SERWIS</b-button
        >
      </div>
      <b-row v-if="returnServices" class="mt-2">
        <b-table
          :fields="fields"
          :items="returnServices"
          :sort-desc="true"
          :sort-by.sync="sortBy"
        >
          <template #cell(edit)="row">
            <b-button
              variant="warning"
              size="sm"
              @click="editService(row.item)"
              class="mr-1"
            >
              Edytuj
            </b-button>
            <b-button
              variant="danger"
              size="sm"
              @click="removeService(row.item)"
              class="mr-1"
            >
              Usuń
            </b-button>
          </template>
        </b-table>
      </b-row>
    </b-tab>
    <b-modal
      centered
      id="addDriver"
      ok-title="Dodaj"
      cancel-title="Anuluj"
      size="lg"
      title="Przypisz kierowcę do pojazdu"
      @ok="addDriver"
      :ok-disabled="selectedDriver && selectedDriver.fullName ? false : true"
    >
      <b-row class="modal-rows justify-content-center">
        <b-col
          class="text-center driver pointer"
          :class="[
            selectedDriver && selectedDriver.fullName === item.fullName
              ? 'selected'
              : '',
          ]"
          @click="selectDriver(item)"
          cols="5"
          v-for="item in returnNonDrivers"
          :key="item.fullName"
        >
          <div class="box">{{ item.fullName }}</div>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal
      centered
      id="maitenance"
      ok-title="Dodaj"
      @ok="addGrounded"
      cancel-title="Anuluj"
      size="lg"
      title="Dodaj nowe wyłączenie dla pojazdu"
    >
      <h3>Start wyłączenia</h3>
      <b-form-group label="Wybierz dzień, od którego auto jest wyłączone">
        <b-form-datepicker
          v-model="m.dayStart"
          :start-weekday=1
          placeholder="Nie wybrano daty"
        />
      </b-form-group>
      <b-form-group label="Wybierz zmianę, od której auto jest niedostępne">
        <b-form-select v-model="m.shiftStart" :options="['Dzień', 'Noc']">
        </b-form-select>
      </b-form-group>
      <h3>Koniec wyłączenia</h3>
      <b-form-group
        label="Wybierz dzień, w którym kończy się wyłączenie pojazdu"
      >
        <b-form-datepicker :start-weekday=1 v-model="m.dayEnd" placeholder="Nie wybrano daty" />
      </b-form-group>
      <b-form-group label="Wybierz zmianę, do której auto jest wyłączone">
        <b-form-select v-model="m.shiftEnd" :options="['Dzień', 'Noc']">
        </b-form-select>
      </b-form-group>
      <b-form-group label="Ukryj auto w kalendarzu publicznym">
        <b-form-checkbox
          size="lg"
          v-model="m.notOnStation"
          name="check-button"
          switch
        />
      </b-form-group>
    </b-modal>
    <b-modal
      centered
      id="editmaitenance"
      ok-title="Zapisz"
      @ok="editGrounded"
      cancel-title="Anuluj"
      size="lg"
      title="Edytuj wyłączenie dla pojazdu"
    >
      <h3>Start wyłączenia</h3>
      <b-form-group
        v-if="edit"
        label="Wybierz dzień, od którego auto jest wyłączone"
      >
        <b-form-datepicker
          v-model="edit.attributes.dayStart"
          placeholder="Nie wybrano daty"
        />
      </b-form-group>
      <b-form-group
        v-if="edit"
        label="Wybierz zmianę, od której auto jest niedostępne"
      >
        <b-form-select
          v-model="edit.attributes.shiftStart"
          :options="['Dzień', 'Noc']"
        >
        </b-form-select>
      </b-form-group>
      <h3>Koniec wyłączenia</h3>
      <b-form-group
        v-if="edit"
        label="Wybierz dzień, w którym kończy się wyłączenie pojazdu"
      >
        <b-form-datepicker
          v-model="edit.attributes.dayEnd"
          placeholder="Nie wybrano daty"
        />
      </b-form-group>
      <b-form-group
        v-if="edit"
        label="Wybierz zmianę, do której auto jest wyłączone"
      >
        <b-form-select
          v-model="edit.attributes.shiftEnd"
          :options="['Dzień', 'Noc']"
        >
        </b-form-select>
      </b-form-group>
      <b-form-group v-if="edit" label="Ukryj auto w kalendarzu publicznym">
        <b-form-checkbox
          size="lg"
          v-model="edit.attributes.notOnStation"
          name="check-button"
          switch
        />
      </b-form-group>
    </b-modal>
    <b-modal
      centered
      id="service"
      ok-title="Dodaj"
      cancel-title="Anuluj"
      size="lg"
      @ok="sendService"
      title="Dodaj nowy serwis dla pojazdu"
    >
      <h3>Dzień serwisu</h3>
      <b-form-group label="Data serwisu">
        <b-form-datepicker :start-weekday=1 v-model="service.date" placeholder="Wybierz datę" />
      </b-form-group>
      <b-form-group label="Godzina serwisu">
        <b-input-group>
        <b-form-input v-model="service.hour" type="text" placeholder="HH:MM:SS"></b-form-input>
        <b-input-group-append>
        <b-form-timepicker
          type="time"
          placeholder="Wybierz godzinę"
          v-model="service.hour"
          :options="['Dzień', 'Noc']"
        />
        </b-input-group-append>

        </b-input-group>

      </b-form-group>
      <b-form-group label="Typ serwisu">
        <b-form-input
          placeholder="np. Wymiana oleju"
          v-model="service.text"
          :options="['Dzień', 'Noc']"
        >
        </b-form-input>
      </b-form-group>
    </b-modal>
    <b-modal
      centered
      id="service-edit"
      ok-title="Zapisz"
      cancel-title="Anuluj"
      size="lg"
      @ok="saveService"
      title="Edytuj serwis dla pojazdu"
      v-if="editServiceId"
    >
      <h3>Dzień serwisu</h3>
      <b-form-group label="Data serwisu">
        <b-form-datepicker :start-weekday=1 v-model="editServiceId.serviceDate" placeholder="Wybierz datę" />
      </b-form-group>
      <b-form-group label="Godzina serwisu">
        <b-input-group>
        <b-form-input v-model="editServiceId.serviceTime" type="text" placeholder="HH:MM:SS"></b-form-input>
        <b-input-group-append>
        <b-form-timepicker
          type="time"
          placeholder="Wybierz godzinę"
          v-model="editServiceId.serviceTime"
          :options="['Dzień', 'Noc']"
        />
        </b-input-group-append>

        </b-input-group>

      </b-form-group>
      <b-form-group label="Typ serwisu">
        <b-form-input
          placeholder="np. Wymiana oleju"
          v-model="editServiceId.desc"
          :options="['Dzień', 'Noc']"
        >
        </b-form-input>
      </b-form-group>
    </b-modal>
  </b-tabs>
</template>

<script>
export default {
  data() {
    return {
      selectedDriver: null,
      notValid: false,
      isLoading: false,
      edit: null,
      sortBy: "serviceDate",
      editServiceId: null,

      makes: [
        { value: null, text: "Wybierz markę" },
        { value: "Toyota", text: "Toyota" },
        { value: "Skoda", text: "Skoda" },
      ],
      models: [
        { make: "Toyota", models: ["Yaris", "Auris", "Corolla", "Camry"] },
        { make: "Skoda", models: ["Fabia", "Rapid", "Octavia", "Superb"] },
      ],
      m: {
        shiftStart: null,
        shiftEnd: null,
        dayStart: null,
        dayEnd: null,
        notOnStation: null,
      },
      service: {
        date: null,
        hour: null,
        text: null,
      },
      color: [
        { value: "Czarny", text: "Czarny" },
        { value: "Szary", text: "Szary" },
        { value: "Biały", text: "Biały" },
      ],
      insCompany: [
        {
          value: "Powszechny Zakład Ubezpieczeń",
          text: "Powszechny Zakład Ubezpieczeń",
        },
        {
          value: "Ergo Hestia",
          text: "Ergo Hestia",
        },
        {
          value: "Warta",
          text: "Warta",
        },
      ],
      fields: [
        {
          key: "serviceDate",
          label: "Data serwisu",
          sortable: true,
        },
        {
          key: "serviceTime",
          label: "Godzina serwisu",
          formatter: (value, key, item) => {
            return value.slice(0, 5);
          },
        },
        {
          key: "desc",
          label: "Opis",
        },
        {
          key: "edit",
          label: "Edycja",
        },
      ],
    };
  },
  mounted() {
    this.fetchDrivers();
  },
  methods: {
    sendForm() {
      if (this.getSingleCar) {
        this.$store
          .dispatch("updateCar", this.getSingleCar)
          .then((res) => {
            this.$bvModal.msgBoxOk("Zmiany zapisane pomyślnie.", {
              headerBgVariant: "primary",
              title: "Zapisano pomyślnie",
              headerTextVariant: "light",
              centered: true,
            });
          })
          .catch((err) => {
            this.$bvModal.msgBoxOk(
              "Pojawił się problem - może jest to tymczasowy błąd spróbuj ponownie później.",
              {
                headerBgVariant: "danger",
                title: "Błąd",
                headerTextVariant: "light",
                centered: true,
              }
            );
          });
      } else {
        // loop and look for errors
        this.notValid = true;
      }
    },
    sendService() {
      const carId = this.$route.params.id;
      if (this.service.date && this.service.hour && this.service.text) {
        this.$store.dispatch("addService", {
          carId,
          ...this.service,
          week: this.serviceWeek,
          reg: this.getSingleCar.reg,
        });
      } else {
        this.$bvModal.msgBoxOk("Wprowadź wszystkie dane.", {
          headerBgVariant: "danger",
          title: "Błąd",
          headerTextVariant: "light",
          centered: true,
        });
      }
      //
      this.service.date = null;
      this.service.hour = null;
      this.service.text = null;
    },
    removeService(item) {
      this.$store.dispatch("removeService", item.id);
    },
    goBack() {
      this.$router.go(-1);
    },
    saveService(){
      this.$store.dispatch("updateService", {...this.editServiceId})
    },
    selectDriver(val) {
      this.selectedDriver = val;
    },
    addDriver() {
      this.$store.dispatch("addDriver", {
        fullName: this.selectedDriver.fullName,
        phoneNumber: this.selectedDriver.phone,
        primaryCar: this.getSingleCar.id,
      });
    },
    async fetchDrivers() {
      this.isLoading = true;
      await this.$store
        .dispatch("fetchDetails", {
          week: this.$store.state.settings.adminWeek,
          year: this.$store.state.settings.adminYear,
        })
        .catch((error) => console.log(error));
      await this.$store.dispatch("fetchCarDrivers", this.getSingleCar.id);
      await this.$store.dispatch("getGrounded", this.getSingleCar.id);
      this.isLoading = false;
    },
    async remove(item) {
      await this.$store.dispatch("removeDriver", item.id);
    },
    async fetchServices() {
      await this.$store.dispatch("getSingleServices", this.getSingleCar.id);
    },
    async addGrounded() {
      const carReg = this.getSingleCar.reg;
      const carId = this.getSingleCar.id;
      const week = this.serviceWeek;
      if (
        this.m.shiftStart &&
        this.m.shiftEnd &&
        this.m.dayStart &&
        this.m.dayEnd
      ) {
        await this.$store.dispatch("addGrounded", {
          ...this.m,
          carReg,
          carId,
          week,
        });
      }
    },
    async showGroundedModal(item) {
      this.edit = item;
      this.$bvModal.show("editmaitenance");
    },
    async editGrounded() {
      const id  = this.edit.id;
      const dayEnd = this.edit.attributes.dayEnd
      const dayStart = this.edit.attributes.dayStart
      const notOnStation = this.edit.attributes.notOnStation
      const shiftEnd = this.edit.attributes.shiftEnd
      const shiftStart = this.edit.attributes.shiftStart
      if(dayEnd && dayStart && shiftEnd && shiftStart){
                await this.$store.dispatch("editGrounded", {
          dayEnd,
          dayStart,
          notOnStation,
          shiftEnd,
          shiftStart,
          id,
        });
      }
      console.log(id)
    },

    async editService(id) {
      this.editServiceId = id;
      this.$bvModal.show('service-edit')
    },


    async removeGrounded(id) {
      await this.$store.dispatch("removeGrounded", id);
    },
    async deleteCar(id) {
      this.$bvModal
        .msgBoxConfirm(
          "Pojazd zostanie usunięty tylko i wyłączenie w niewygenerowanych tygodniach. Dla obecnego tygodnia użyj wyłączenia pojazdu z opcją ukrycia w kalendarzu publicznym. Po ukryciu i aktualizacji możesz usunąć auto. Auto usuwane jest na stałe, jeżeli auto wróci na stacje będzie wymagane ponowne dodanie.",
          {
            headerBgVariant: "danger",
            title: "Potwierdź usunięcie pojazdu",
            headerTextVariant: "light",
            centered: true,
            cancelTitle: "Anuluj",
            okTitle: "Potwierdzam usunięcie",
          }
        )
        .then((val) => {
          this.$store.dispatch("deleteCar", id);
          this.goBack();
        })
        .catch((err) => {
          console.log("wystąpił błąd");
        });
    },
  },

  computed: {
    returnModels() {
      if (this.getSingleCar.make) {
        const selected = this.models.find(
          (el) => el.make === this.getSingleCar.make
        );
        const models = [{ value: null, text: "Wybierz model" }];
        selected.models.forEach((element) => {
          models.push({ value: element, text: element });
        });
        return models;
      } else {
        return [{ value: null, text: "" }];
      }
    },
    returnYears() {
      const today = new Date();
      const years = [];
      for (let i = 0; i > -22; i--) {
        years.push({
          value: today.getFullYear() + i,
          text: today.getFullYear() + i,
        });
      }
      return years;
    },
    getSingleCar() {
      const allCars = this.$store.getters["returnCars"];
      const carId = this.$route.params.id;
      const findOne = allCars.find((element) => element.id == carId);
      return findOne;
    },
    formatNames() {
      const details = this.$store.getters["getDetails"];
      const schedules = details.schedules;
      const drivers = [];
      for (let i = 0; i < schedules.length; i++) {
        let fullName = schedules[i].attributes.fullName;
        let phone = schedules[i].attributes.phone;
        drivers.push({ fullName, phone });
      }
      return drivers;
    },
    getSingleCarDrivers() {
      return this.$store.getters["returnCarDrivers"];
    },
    returnServices() {
      const carId = this.$route.params.id;
      return this.$store.getters["getServices"].filter(
        (el) => el.carId == carId
      );
    },
    returnGrounded() {
      const groundedDays = this.$store.getters["singleGrounded"];
      return !this.isLoading && groundedDays;
    },
    returnNonDrivers() {
      let keys = null;
      let missing = null;
      if (this.getSingleCarDrivers.length) {
        keys = this.getSingleCarDrivers.reduce(
          (acc, curr) => ((acc[curr.phoneNumber] = true), acc),
          {}
        );
        missing = this.formatNames.filter((item) => !keys[item.phone]);
      }
      return (!this.isLoading && missing) || this.formatNames;
    },
    serviceWeek() {
      if (this.service.date) {
        return this.$dayjs(this.service.date).week();
      }
      return null;
    },

  },
};
</script>

<style scoped>
.grounded-row {
  gap: 20px 0;
}
.header-back {
  cursor: pointer;
}
.grounded-box {
  background: #fff;
  padding: 15px;
  width: 80%;
  border-radius: 15px;
}
.grounded-reg {
  font-weight: 600;
  font-size: 1.2em;
}
.driver {
  border: 1px solid gray;
  padding: 10px;
}
.remove {
  color: rgb(189, 15, 15);
}
.pointer {
  cursor: pointer;
}
.modal-rows {
  margin: 20px;
  gap: 20px;
}
.selected {
  background-color: yellow;
}
.space {
  row-gap: 15px;
}
.start {
  background: rgb(186, 255, 186);
  padding: 10px;
  border-radius: 10px;
}
.end {
  background: rgb(255, 164, 164);
  padding: 10px;
  border-radius: 10px;
}
</style>
