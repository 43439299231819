import Vue from 'vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import Vuex from 'vuex'
import store from '../store/index.js'
import axios from 'axios'
import VueAxios from 'vue-axios'
import routes from './routes/routes'
import VueApexCharts from 'vue-apexcharts'
// dayjs
const dayjs = require('dayjs')
require('dayjs/locale/pl')
const weekday = require('dayjs/plugin/weekday')
const week = require('dayjs/plugin/weekOfYear')
const isBetween = require('dayjs/plugin/isBetween')
dayjs.extend(isBetween)
dayjs.extend(weekday)
dayjs.extend(week)
dayjs.locale('pl')
dayjs().format()
    // custom css
import './assets/css/peoplescheduler.scss'

Vue.prototype.$dayjs = dayjs
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(Vuex)
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)
Vue.use(VueAxios, axios)

Vue.use(VueRouter)
const router = new VueRouter({
    routes,
    mode: 'history',
    linkActiveClass: 'nav-item active',
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    }
})

Vue.config.productionTip = false

new Vue({
    render: h => h(App),
    store,
    router
}).$mount('#app')