<template>
  <b-tab title="Nowy pojazd">
    <b-alert variant="danger" dismissible v-model="notValid"
      >Wprowadziłeś niekompletne dane - przed kolejną próbą zweryfikuj
      poprawność danych.</b-alert
    >
    <h3>Dane pojazdu</h3>
    <b-row class="mb-4">
      <b-col cols="6" lg="4" md="6" sm="12">
        <div role="group">
          <label for="reg">Numer rejetracyjny:</label>
          <b-input id="reg" debounce="1500" trim v-model="form.reg" />
        </div>
      </b-col>
      <b-col cols="6" lg="4" md="6" sm="12">
        <div role="group">
          <label for="marka">Marka:</label>
          <b-form-select id="marka" v-model="form.make" :options="makes" />
        </div>
      </b-col>
      <b-col cols="6" lg="4" md="6" sm="12">
        <div role="group">
          <label for="model">Model:</label>
          <b-form-select
            id="model"
            v-model="form.model"
            :options="returnModels"
          />
        </div>
      </b-col>
      <b-col cols="6" lg="4" md="6" sm="12">
        <div role="group">
          <label for="year">Rok produkcji:</label>
          <b-form-select id="year" v-model="form.year" :options="returnYears" />
        </div>
      </b-col>
      <b-col cols="6" lg="4" md="6" sm="12">
        <div role="group">
          <label for="year">Kolor:</label>
          <b-form-select id="year" v-model="form.color" :options="color" />
        </div>
      </b-col>
      <b-col cols="6" lg="4" md="6" sm="12">
        <div role="group">
          <label for="vin">Numer VIN:</label>
          <b-input trim id="vin" debounce="1500" v-model="form.vin" />
        </div>
      </b-col>
      <b-col cols="6" lg="4" md="6" sm="12">
        <div role="group">
          <label for="entryDate">Data wejścia do floty</label>
          <b-input type="date" id="entryDate" v-model="form.entryDate" />
        </div>
      </b-col>
      <b-col cols="6" lg="4" md="6" sm="12">
        <div role="group">
          <label for="service">Następny przegląd okresowy:</label>
          <b-input type="date" id="entryDate" v-model="form.service" />
        </div>
      </b-col>
      <b-col cols="6" lg="4" md="6" sm="12">
        <div role="group">
          <label for="exitDate">Wyjście z floty</label>
          <b-input type="date" id="exitDate" v-model="form.exitDate" />
        </div>
      </b-col>
      <b-col cols="6" lg="4" md="6" sm="12">
        <div role="group">
          <label for="priority">Piorytet</label>
          <b-form-spinbutton min="0" max="10" type="date" id="priority" v-model="form.priority" />
        </div>
      </b-col>
      <b-col cols="6" lg="4" md="6" sm="12">
        <div role="group">
          <label for="flotman">Numer urządzenia GPS</label>
          <b-input id="flotman" v-model="form.flotmanId" />
        </div>
      </b-col>
      <b-col cols="6" lg="4" md="6" sm="12">
        <div role="group">
          <label for="isRental">Auto dostępne w wynajmie</label>
          <b-checkbox id="isRental" v-model="form.isRental" />
        </div>
      </b-col>
    </b-row>
    <h3>Ubezpieczenie</h3>
    <b-row>
      <b-col cols="6" lg="4" md="6" sm="12">
        <div role="group">
          <label for="insName">Nazwa zakładu ubezpieczeń:</label>
          <b-form-select
            type="insName"
            id="entryDate"
            v-model="form.insName"
            :options="insCompany"
          />
        </div>
      </b-col>
      <b-col cols="6" lg="4" md="6" sm="12">
        <div role="group">
          <label for="insNumber">Numer polisy:</label>
          <b-input trim id="insNumber" v-model="form.insNum" />
        </div>
      </b-col>
      <b-col cols="6" lg="4" md="6" sm="12">
        <div role="group">
          <label for="insEnd">Zakończenie polisy:</label>
          <b-input type="date" trim id="insEnd" v-model="form.insEnd" />
        </div>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col>
        <b-button @click="sendForm" class="float-right" variant="primary"
          >Dodaj</b-button
        >
      </b-col>
    </b-row>
  </b-tab>
</template>

<script>
export default {
  data() {
    return {
      form: {
        model: null,
        make: null,
        year: null,
        color: null,
        vin: null,
        reg: null,
        entryDate: null,
        exitDate: null,
        service: null,
        insName: null,
        insNum: null,
        insEnd: null,
        flotmanId: null,
        priority: 1,
        isRental: false
      },
      notValid: false,
      makes: [
        { value: null, text: "Wybierz markę" },
        { value: "Toyota", text: "Toyota" },
        { value: "Skoda", text: "Skoda" },
      ],
      models: [
        { make: "Toyota", models: ["Yaris", "Auris", "Corolla", "Camry"] },
        { make: "Skoda", models: ["Fabia", "Rapid", "Octavia", "Superb"] },
      ],
      color: [
        { value: "Czarny", text: "Czarny" },
        { value: "Szary", text: "Szary" },
        { value: "Biały", text: "Biały" },
      ],
      insCompany: [
        {
          value: "Powszechny Zakład Ubezpieczeń",
          text: "Powszechny Zakład Ubezpieczeń",
        },
        {
          value: "Ergo Hestia",
          text: "Ergo Hestia",
        },
        {
          value: "Warta",
          text: "Warta",
        },
      ],
    };
  },
  methods: {
    sendForm() {
      if (
        this.form.model &&
        this.form.make &&
        this.form.reg &&
        this.form.vin &&
        this.form.insNum &&
        this.form.color &&
        this.form.entryDate &&
        this.form.service &&
        this.form.insName &&
        this.form.insEnd &&
        this.form.exitDate &&
        this.form.year
      ) {
        this.$store.dispatch("addCar", this.form).then((res) => {
          this.$bvModal.msgBoxOk("Auto zostało dodane pomyślnie.", {
            headerBgVariant: "primary",
            title: "Dodano pomyślnie",
            headerTextVariant: "light",
            centered: true,
          });
        });
      } else {
        // loop and look for errors
        this.notValid = true;
      }
    },
    validate() {
      if (this.form.reg)
        this.form.reg.length <= 7 && this.form.reg.length >= 3
          ? (this.valid.reg = true)
          : (this.valid.reg = false);
      if (this.form.vin)
        this.form.vin.length === 17
          ? (this.valid.vin = true)
          : (this.valid.vin = false);
      if (this.form.insNum)
        this.form.insNum.length >= 3
          ? (this.valid.insNum = true)
          : (this.valid.insNum = false);
      this.form.insEnd.length
        ? (this.valid.insEnd = true)
        : (this.valid.insEnd = false);
    },
  },

  computed: {
    returnModels() {
      if (this.form.make) {
        const selected = this.models.find((el) => el.make === this.form.make);
        const models = [{ value: null, text: "Wybierz model" }];
        selected.models.forEach((element) => {
          models.push({ value: element, text: element });
        });
        return models;
      } else {
        return [{ value: null, text: "" }];
      }
    },
    returnYears() {
      const today = new Date();
      const years = [];
      for (let i = 0; i > -22; i--) {
        years.push({
          value: today.getFullYear() + i,
          text: today.getFullYear() + i,
        });
      }
      return years;
    },
  },
};
</script>
