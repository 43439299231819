<template>
  <div>
    <b-modal
      title="Twoje dane"
      @ok="sendForm"
      ok-title="Prześlij"
      cancel-title="Anuluj"
      centered
      size="lg"
      id="add-emp"
    >
      <b-alert show>Pamiętaj aby wprowadzić poprawny numer telefonu!</b-alert>
      <b-form-group :invalid-feedback="invalidName">
        <label for="fullName">Imię i nazwisko</label>
        <b-form-input
          v-model="fullName"
          :state="!formValidate || null"
          trim
          id="fullName"
        />
      </b-form-group>
      <b-form-group
        label="Numer telefonu"
        label-for="phoneNumber"
        invalid-feedback="Wprowadź poprawny numer telefonu"
      >
        <b-input-group prepend="+48">
          <b-form-input v-model="phone" trim type="number" id="phoneNumber" />
        </b-input-group>
      </b-form-group>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      fullName: null,
      phone: null,
      formSent: false,
    };
  },
  methods: {
    addEmp(item) {

    },
    sendForm() {
      this.formSent = true;
      const phone = this.phone;
      const fullName = this.fullName;

      if (this.phone && this.fullName) {
        this.$store.dispatch("addDriverToList", {
          fullName,
          phone,
        });
        emit("driverAdded");
      } else {
        this.$bvModal.msgBoxOk("Upewnij się, że podałeś wszystkie dane.", {
          headerBgVariant: "danger",
          title: "Wystąpił błąd",
          okVariant: "danger",
          headerTextVariant: "light",
          centered: true,
        });
      }
    },
  },
  computed: {
    formValidate() {
      if (
        this.fullName &&
        this.fullName.trim().indexOf(" ") !== -1 &&
        !this.formSent &&
        this.phone &&
        this.phone.length > 6 &&
        this.email &&
        this.email.length > 5
      ) {
        return false;
      } else {
        return true;
      }
    },
    invalidName() {
      if (this.fullName && this.fullName.trim().indexOf(" ") === -1) {
        return "Wprowadź imię i nazwisko";
      }
    },
  },
};
</script>

<style>
.row .col .singleday {
  border: 1px solid black;
  text-align: center;
  height: 100px;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
.selected {
  background-color:#808080;
  color: #fff;
}
</style>
