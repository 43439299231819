<template>
  <b-card
    header-class="icon"
    footer-class="icon"
    class="custom-card"
    border-variant="light"
    :class="colorBoxes"
    v-model="shiftOption"
    @click="changeShift(day)"
  >
    <template #header>
      <b-icon v-if="icon" :icon="icon" />
    </template>
    {{ hasShiftError ? "Popraw zmianę" : shiftOption }}
    <p>{{ day }}.{{ month }}.{{ year }}</p>
    <p>{{ weekDay }}</p>
    <div class="bonus" v-if="shiftOption === 'Wolne' && (bonuses.day.value || bonuses.night.value)" ><strong v-if="bonuses.day.value" class="d-block">Bonus do {{bonuses.day.value}} PLN za dzień</strong><strong v-if="bonuses.night.value" class="d-block">Bonus do {{bonuses.night.value}} PLN za noc</strong> </div>
    <div class="bonus" v-if="shiftOption === 'Dzień' && bonuses.day.value" ><strong>3 TOP zgarnia bonus </strong></div>
    <div class="bonus" v-if="shiftOption === 'Noc' && bonuses.night.value && !hasShiftError" ><strong>3 TOP zgarnia bonus</strong></div>
    <template #footer>
      <b-progress :max="maxShifts.day + maxShifts.night" class="mt-2" variant="success">
        <b-progress-bar
          variant="success"
          :value="shiftOption !== 'Wolne' ? currentDay + 1 : currentDay"
        ></b-progress-bar>
        <b-progress-bar
          variant="success"
          :value="currentNight"
        ></b-progress-bar>
      </b-progress>
    </template>
  </b-card>
</template>

<script>
import Footer from "./Footer.vue";
export default {
  components: { Footer },
  props: [
    "day",
    "month",
    "year",
    "isWeekend",
    "weekDay",
    "dayLimits",
    "currentDay",
    "currentNight",
    "anotherDays",
    "defaultLimit",
    "currentChoose",
    "errorDays",
    "bonuses"
  ],
  data() {
    return {
      shiftOption: "Wolne",
      icon: "house-door",
    };
  },
  methods: {
    changeShift(item) {
      if (this.shiftOption === "Wolne") {
        this.currentDay + 1 > this.maxShifts.day
          ? (this.shiftOption = "Dzień rezerwa")
          : (this.shiftOption = "Dzień");
        this.icon = "sun";
      } else if (
        this.shiftOption === "Dzień" ||
        this.shiftOption === "Dzień rezerwa"
      ) {
        this.currentNight + 1 > this.maxShifts.night
          ? (this.shiftOption = "Noc rezerwa")
          : (this.shiftOption = "Noc");
        this.icon = "moon";
      } else if (
        this.shiftOption === "Noc" ||
        this.shiftOption === "Noc rezerwa"
      ) {
        this.shiftOption = "Wolne";
        this.icon = "house-door";
      }
      this.$emit("dayChanged", {
        shiftOption: this.shiftOption,
        day: this.day,
        month: this.month,
        year: this.year,
        isWeekend: this.isWeekend,
        carLimitDay: this.dayLimit,
        carLimitNight: this.nightLimit,
        currentDay: this.currentDay,
        currentNight: this.currentNight,
      });
      //  this.changeCapacity(this.shiftOption) co to jest?
    },
  },
  computed: {
    colorBoxes() {
      if (this.shiftOption === "Noc" && this.hasShiftError) {
        return "error";
      }
      // if(this.shiftOption === "Do dyspozycji"){
      //   return 'idk'
      // }
      if (this.isWeekend) {
        if (this.isWeekend && this.shiftOption !== "Wolne") {
          return this.shiftOption === "Noc" ? "choosed-night" : "choosed";
        }
        if (this.isWeekend) {
          return "weekend";
        }
      }
      if (this.shiftOption !== "Wolne") {
        return this.shiftOption === "Noc" ? "choosed-night" : "choosed";
      }
    },
    maxShifts() {
      const boxDay = this.dayLimits.find(
        (item) =>
          item.dayNumber === this.day &&
          item.month === this.month &&
          item.year === this.year &&
          item.day > 0
      );
      const boxNight = this.dayLimits.find(
        (item) =>
          item.dayNumber === this.day &&
          item.month === this.month &&
          item.year === this.year &&
          item.night > 0
      );
      if (boxDay || boxNight) {
        return {
          day: boxDay ? boxDay.day : this.defaultLimit,
          night: boxNight ? boxNight.night : this.defaultLimit,
        };
      } else {
        return { day: this.defaultLimit, night: this.defaultLimit };
      }
    },
    currentPeople() {
      if (this.shiftOption === "Wolne") {
        return this.currentDay + this.currentNight + this.currentChoose;
      } else {
        return this.currentDay + this.currentNight + this.currentChoose + 1;
      }
    },
    hasShiftError() {
      let hasError = this.errorDays.find((item) => item["day"] === this.day);
      return hasError ? true : false;
    },
  },
};
</script>

<style scoped>
.custom-card {
  background-color: #A9A9A9;
  border: none;
  color: #fff;
  width: 175px;
  height: 250px;
  text-align: center;
  cursor: pointer;
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-radius: 24px;
}
.choosed {
  background-color: #ffa400;
}
.choosed-night {
  background-color: #13024e;
}
.idk {
  background-color: #116530;
}

.error {
  background-color: rgb(216, 58, 58);
}
.icon {
  border: 0;
  font-size: 30px;
}
@media only screen and (max-width: 1000px) {
  .custom-card {
    width: 80%;
    margin: 0 auto;
    height: 300px;
    margin-top: 20px;
  }
}
.custom-card.weekend {
  background-color: #808080;
}
.card-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.bonus {
  background-color: #d44118;
  color: #fff;
  border-radius: 16px;
  padding: 4px;
}
</style>
