<template>
  <b-modal
    id="pin"
    title="Wprowadź PIN aby uzyskać dostęp"
    centered
    hide-header-close
    hide-footer
    no-close-on-esc
    no-close-on-backdrop
    ok-title="Przejdź dalej"
    header-class="pin-title"
  >
  <div class='input-zone'>
      <input @keyup="move(1)" v-model.number="firstNum" ref="firstNum" type="number" min="0" max='9'  size="1" class="single-pin"/>
      <input @keyup="move(2)" v-model.number="secondNum" ref="secondNum" type="number" min="0" max='9'  size="1" class="single-pin"/>
      <input @keyup="move(3)" v-model.number="thirdNum" ref="thirdNum" type="number" min="0" max='9'  size="1" class="single-pin"/>
      <input ref="fourthNum" v-model.number="fourthNum" type="number" min="0" max='9'  size="1" class="single-pin"/>
  </div>
  <div class="button-zone d-flex justify-content-between" >
    <h4 @click="reset" class="pointer">Reset<b-icon icon="x-circle" class="ic"></b-icon></h4>
    <h4 @click="validate" class="pointer">Dalej<b-icon icon="caret-right" class="ic"/></h4>
  </div>
  
  </b-modal>
</template>

<script>
export default {
  data(){
    return{
      firstNum: null,
      secondNum: null,
      thirdNum: null,
      fourthNum: null,
      pinValid: null,
    }
  },
  mounted(){
    this.$root.$emit('bv::show::modal', 'pin', '#input-1')
    this.autoLogin()
  },
    methods: {
      move(num){
        if(num === 1){
        this.$refs.secondNum.focus()
        }
        if(num === 2){
        this.$refs.thirdNum.focus()
        }
        if(num === 3){
        this.$refs.fourthNum.focus()
        }
      },
      reset(){
        this.firstNum = null;
        this.secondNum = null;
        this.thirdNum = null;
        this.fourthNum = null;
        this.$refs.firstNum.focus()
      },
      async validate(){
          const input = [this.firstNum, this.secondNum, this.thirdNum, this.fourthNum].join("");
          await this.$store.dispatch('checkPin', input).then((res) => {
            if(this.$store.state.validPin){
              localStorage.setItem('pin', input)
            }
          })
      },
      async autoLogin(){
          const pin = localStorage.getItem('pin')
          await this.$store.dispatch('checkPin', pin)
      }
    },
}
</script>


<style>
#pin.modal.fade {
  background-color: rgba(21, 58, 103, 0.1) !important;
}
.pin-title{
   
    display: block;
    justify-content: center !important;
    border-bottom: 1px solid black;;
}
.input-zone{
    display: flex;
    align-content: center;
    justify-content: center;
}
.single-pin {
    min-width: 45px;
    height: 60px;
    margin: 10px 14px;
}
.single-pin {
    font-size: 2em
}
.button-zone{
  text-align: center;
  margin-top: 20px;
}
.pointer{
    cursor: pointer;
}
.ic{
  margin: 0 10px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.input-zone input[type=number] {
  -moz-appearance: textfield;
  text-align: center;
}
</style>