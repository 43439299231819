import Vue from 'vue';
import Vuex from 'vuex'
Vue.use(Vuex)

import getters from './getters'
import actions from './actions'
import mutations from './mutations'
export default new Vuex.Store({
    state: {
        data: null,
        dailyResults: null,
        weeklyResults: null,
        userFetch: null,
        user: null,
        usersInEdit: null,
        settings: null,
        service: [],
        weekServices: null,
        drivers: [],
        vehicles: [],
        validPin: false,
        calendarEvents: [],
        publicCalendar: null,
        currentCalendar: null,
        dashboardResults: null,
        dashboardWeeklyResults: null,
        allGenerated: null,
        previusWeek: null,
        allDrivers: null,
        generatedWeeks: [],
        rentals: null,
        fetchedResults: null,
        fuelEmp: null,
        fuelCalendar: null,
        savedFuel: null,
        bonuses: [],
        calendarMode: 'current',
        grounded: { single: [], all: [] },
        calPosition: 0,
        selected: { year: null, month: null, week: null, weekVal: null },
        settingsDetails: { amount: null, schedules: null, capacityByDay: null, shiftsAmount: null, activeLimits: null },
        prevMonth: [],
        assignedDrivers: null,
        otherResults: null,
    },
    mutations: mutations,
    actions: actions,
    getters: getters,
})