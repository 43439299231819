function download_csv_file(items, fileTitle) {

    //define the heading for each row of the data
    var string = '';
    //merge the data with CSV
    const day = items.day
    const night = items.nights
    for (let i = 0; i < day.length; i++) {
        string += `${day[i].day}.${day[i].month}`;
        string += '\r\n';
        string += `Rej D,Kierowca D,,Rej N,Kierowca N`
        string += '\r\n';

        for (let a = 0; a < day[i].dayArray.length; a++) {
            string += `${day[i].dayArray[a].reg},${day[i].dayArray[a].driver},,`
            string += `${night[i].dayArray[a].reg},${night[i].dayArray[a].driver}`
            string += '\r\n';
        }

    }

    //display the created CSV data on the web browser 


    var exportedFilenmae = fileTitle + '.csv' || 'export.csv';
    var universalBOM = "\uFEFF";

    var blob = new Blob([string], { type: 'text/csv;charset=UTF-8,' });
    if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, exportedFilenmae);
    } else {
        var link = document.createElement("a");
        if (link.download !== undefined) {
            var url = URL.createObjectURL(blob);
            link.setAttribute('lang', 'pl')
            link.setAttribute("href", 'data:text/csv;charset=UTF-8,' + encodeURIComponent(universalBOM + string));
            link.setAttribute("download", exportedFilenmae);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
}


export default download_csv_file