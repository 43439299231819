export default {
    setAvailability(state, payload) {
        state.data = payload.capacityByDay;
        state.settingsDetails.activeLimits = payload.limits;
    },
    addCar(state, payload) {
        state.vehicles.push(payload.data);
    },
    addDriver(state, payload) {
        state.drivers.push(payload.data);
    },
    setDrivers(state, payload) {
        const drivers = [];
        for (let item in payload) {
            drivers.push({ id: payload[item].id, ...payload[item].attributes });
        }
        state.drivers = drivers;
    },
    generateWeek(state, payload) {
        let generated = [];
        for (let item in payload) {
            generated.push({ id: payload[item].id, ...payload[item].attributes });
        }
        state.generatedWeeks = generated;
    },
    updateCar(state, payload) {
        const index = state.vehicles.findIndex((x) => x.id === payload.id);
        state.vehicles[index] = payload;
    },
    setCars(state, payload) {
        const cars = [];
        for (let item in payload) {
            cars.push({ id: payload[item].id, ...payload[item].attributes });
        }
        state.vehicles = cars;
    },
    setRentals(state, payload) {
        const rentals = [];
        console.log(payload)
        for (let item in payload) {
            rentals.push({ id: payload[item].id, ...payload[item].attributes });
        }
        state.rentals = rentals;
    },
    setUserFetch(state, payload) {
        state.userFetch = payload;
    },
    setUsersInEdit(state, payload) {
        state.usersInEdit = payload;
    },
    loginUser(state, payload) {
        state.user = payload;
    },
    setSettings(state, payload) {
        state.settings = payload;
    },
    setDetails(state, payload) {
        state.settingsDetails.amount = payload.amount;
        state.settingsDetails.schedules = payload.schedules;
        state.settingsDetails.capacityByDay = payload.capacityDay;
        state.settingsDetails.shiftsAmount = payload.shiftsAmount;
        state.settingsDetails.activeLimits = payload.dayLimits.data;
    },
    setCalendarMode(state, payload) {
        state.calendarMode = payload;
    },
    setActiveLimits(state, payload) {
        state.settingsDetails.activeLimits.push(payload);
    },
    changeAdminMonthYear(state, payload) {
        if (payload.mode === "week") {
            state.settings.adminWeek = payload.value;
        }
    },
    removeLimit(state, payload) {
        const activeLimits = state.settingsDetails.activeLimits;
        const index = activeLimits.findIndex((item) => item.id === payload);
        if (index !== -1) {
            activeLimits.splice(index, 1);
        }
        state.settingsDetails.activeLimits.slice();
    },
    signSchedule(state, payload) {
        const index = state.userFetch.schedules.findIndex(
            (x) => x.id === payload.id
        );
        state.userFetch.schedules[index].signed = payload.signed;
    },
    toggleCard(state, payload) {
        const calendar = state.calendarEvents.findIndex((x) => x.id === payload.id);
        const index = state.settingsDetails.schedules.findIndex(
            (x) => x.id === payload.id
        );
        state.settingsDetails.schedules[index].fuelCard = payload.fuelCard;
        state.calendarEvents[calendar].fuelCard = payload.fuelCard;
    },
    setCalendar(state, payload) {
        state.calendarEvents = payload;
    },
    setSingleShift(state, payload) {
        const index = state.userFetch.schedules.findIndex(
            (x) => x.id === payload.id
        );
        state.userFetch.schedules[index].attributes.selectedDays.push(
            payload.addedDate
        );
    },
    removeShift(state, payload) {
        const id = parseInt(payload.index);
        const index = state.userFetch.schedules.findIndex((x) => x.id === id);
        const findIndex = state.userFetch.schedules[
            index
        ].attributes.selectedDays.findIndex((e) => e.day === payload.day);
        if (findIndex !== -1) {
            state.userFetch.schedules[index].attributes.selectedDays.splice(
                findIndex,
                1
            );
        }
    },
    setPreviousMonth(state, payload) {
        state.prevMonth = payload;
    },
    logOut(state) {
        state.user = null;
    },
    setSelected(state, payload) {
        if (payload.mode === "y") {
            state.selected.year = payload.item;
        } else {
            state.selected.week = payload.item.text;
            state.selected.weekVal = payload.item.value;
            state.selected.year = payload.item.year;
        }
    },
    changeCalPosition(state, payload) {
        if (payload.add) {
            state.calPosition += 1;
        }
        if (payload.minus) {
            state.calPosition -= 1;
        }
    },
    setPin(state, payload) {
        state.settings.pin = payload;
    },
    pinStatus(state, payload) {
        if (payload.data.passwordValid) {
            state.validPin = true;
        }
    },
    setPublicCalendar(state, payload) {
        state.publicCalendar = payload;
    },
    setCurrentCalendar(state, payload) {
        state.currentCalendar = payload;
    },
    setPreviusWeek(state, payload) {
        state.previusWeek = payload
    },
    removeDriver(state, payload) {
        const index = state.drivers.findIndex((x) => x.id === payload);
        state.drivers.splice(index, 1);
    },
    removeSchedule(state, payload) {
        console.log(payload);
        const index = state.userFetch.schedules.findIndex((x) => x.id === payload);
        state.userFetch.schedules.splice(index, 1);
    },

    setDashboardResults(state, payload){
        state.dashboardResults = payload;
    },
    setDashboardWeeklyResults(state, payload){
        state.dashboardWeeklyResults = payload;
    },

    setResults(state, payload) {
        const formattedResults = [];
        for (let i = 0; i < payload.data.length; i++) {
            formattedResults.push({...payload.data[i].attributes, id: payload.data[i].id })
        }
        state.fetchedResults = formattedResults;
    },
    setDailyResults(state, payload) {

        state.dailyResults = payload;
    },
    setWeeklyResults(state, payload) {
        const formattedResults = [];
        for (let i = 0; i < payload.data.length; i++) {
            formattedResults.push({...payload.data[i].attributes, id: payload.data[i].id })
        }
        state.weeklyResults = formattedResults;
    },
    setAllDrivers(state, payload) {
        const drivers = [];
        for (let item in payload) {
            let attributes = payload[item].attributes;
            let fullName = payload[item].attributes.fullName.split(" ");
            let byName = `${fullName[1]} ${fullName[0]}`;
            drivers.push({...attributes, fullName: byName, id: payload[item].id });
        }
        state.allDrivers = drivers;
    },
    toggleDriver(state, payload) {
        const index = state.allDrivers.findIndex((x) => x.id === payload.id);
        state.allDrivers[index].active = payload.active;
    },
    removeDriverList(state, payload) {
        console.log(payload)
        const index = state.allDrivers.findIndex((x) => x.id === payload);
        console.log(index)
        state.allDrivers.splice(index, 1);
    },
    addStateGrounded(state, payload) {
        if (state.grounded.single.length) {
            state.grounded.single.push(payload);
        } else {
            state.grounded.single.push(payload)
        }
    },
    setSingleGrounded(state, payload) {
        const singleGrounded = payload
        state.grounded.single = singleGrounded;
    },
    setAllGrounded(state, payload) {
        state.grounded.all = payload;
    },
    removeGrounded(state, payload) {
        const index = state.grounded.single.findIndex((x) => x.id === payload);
        state.grounded.single.splice(index, 1);
    },
    setAllGenerated(state, payload) {
        const array = []
        for (let i = 0; i < payload.length; i++) {
            let id = payload[i].id
            let attr = payload[i].attributes
            array.push({ id, ...attr })
        }
        state.allGenerated = array;
    },
    addService(state, payload) {
        const array = []
        for (let i = 0; i < payload.length; i++) {
            let id = payload[i].id
            let attr = payload[i].attributes
            array.push({ id, ...attr })
        }
        state.service = null;
        state.service = array;
    },
    addSingleService(state, payload) {
        const id = payload.id
        state.service.push({ id, ...payload.attributes })
    },
    setWeekServices(state, payload) {
        const array = []
        if (payload.length) {
            for (let i = 0; i < payload.length; i++) {
                let id = payload[i].id
                let attr = payload[i].attributes
                array.push({ id, ...attr })
            }
            state.weekServices = array;
        } else {
            state.weekServices = []
        }
    },
    setPublicServices(state, payload) {
        state.weekServices = payload
    },
    removeService(state, payload) {
        const index = state.service.findIndex((x) => x.id === payload);
        state.service.splice(index, 1);
    },
    setAssignedDrivers(state, payload) {
        state.assignedDrivers = payload
    },
    removeCar(state, payload) {
        const index = state.vehicles.findIndex((x) => x.id === payload);
        state.service.splice(index, 1);
    },
    setBonuses(state, payload){
        state.bonuses = payload;
    },
    addSingleBonus(state, payload){
        state.bonuses.push({attributes: {...payload}})
    },
    addFuelAuth(state, payload){
        state.fuelEmp = payload;
    },
    setFuelWeek(state, payload){
        state.fuelCalendar = payload;
    },
    setSavedFuel(state, payload){
        state.savedFuel = payload;
    },
    setOtherResults(state, payload){
        state.otherResults = payload;
    }
};