<template>
<div id="main">
  <div class="container-fluid">
          <router-view></router-view>
  </div>
</div>
</template>

<script>
export default {
  name: 'AutoGrafik',

 
}
</script>

<style>
html, body {
  height: 100%;
}
body {
  background-color: #EEEEEE;
}
.navbar{
  padding: 0 40px;
  height: 60px;
}
#main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  overflow-x: hidden;
}
.container-fluid {
  padding: 0px !important;
  flex: 1;
}

</style>
