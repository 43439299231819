<template>
  <div class="singleschedule">
    <div class="header-section">
      <h4>
        <span class="header-back">Grafiki</span> / Podgląd dyspozycyjności
      </h4>
      <span class="header-back" @click="goBack">
        Powrót
        <b-icon icon="backspace" />
      </span>
    </div>
    <b-button @click="removeSchedule" variant="danger" class="float-right">Usuń dyspozycyjność</b-button>

    <b-row align-h="between" class="mt-5">
      <b-col md="3">
        <b-card
          header-bg-variant="primary"
          header-text-variant="light"
          align="center"
        >
          <template #header>
            <p class="mb-0">Imię i nazwisko</p>
          </template>
          <p>{{ getSingleId.attributes.fullName }}</p>
        </b-card>
      </b-col>
      <b-col md="4">
        <b-card
          header-bg-variant="primary"
          header-text-variant="light"
          align="center"
        >
          <template #header>
            <p class="mb-0">Adres e-mail</p>
          </template>
          <p>{{ getSingleId.attributes.email }}</p>
        </b-card>
      </b-col>
      <b-col md="3">
        <b-card
          header-bg-variant="primary"
          header-text-variant="light"
          align="center"
        >
          <template #header>
            <p class="mb-0">Data przesłania</p>
          </template>
          <p>{{ formatDate }}</p>
        </b-card>
      </b-col>
      <b-col md="3">
        <b-card
          header-bg-variant="primary"
          header-text-variant="light"
          align="center"
          class="mt-3"
        >
          <template #header>
            <p class="mb-0">Numer telefonu</p>
          </template>
          <p>
            <a>{{ getSingleId.attributes.phone }}</a>
          </p>
        </b-card>
      </b-col>
      <b-col md="3">
        <b-card
          header-bg-variant="primary"
          header-text-variant="light"
          align="center"
          class="mt-3"
        >
          <template #header>
            <p class="mb-0">Karta paliwowa</p>
          </template>
          <p>{{ getSingleId.attributes.fuelCard ? "Tak" : "Nie" }}</p>
        </b-card>
      </b-col>
    </b-row>
    <b-row align-h="between" class="mt-4">
      <b-col md="12">
        <b-card
          header-bg-variant="primary"
          header-text-variant="light"
        >
          <template #header>
            <p class="mb-0">Wybrane zmiany</p>
          </template>
          <b-row>
            <b-col
              v-for="item in sortDates"
              :key="item.day"
              cols="12"
              sm="6"
              lg="3"
            >
              <div
                class="
                  d-flex
                  justify-content-between
                  align-items-center
                  p-3
                  bord
                "
              >
                <div>{{ item.day }}.{{ item.month }}.{{ item.year }}</div>
                <div class="shift-mode">
                  {{ item.shiftOption }}
                </div>
                <div>
                  <b-icon
                    icon="x-circle"
                    @click="
                      deleteShift(
                        item.day,
                        item.month,
                        item.year,
                        item.shiftOption
                      )
                    "
                    class="delete"
                    scale="2"
                    variant="danger"
                  ></b-icon>
                </div>
              </div>
            </b-col>
          </b-row>
          <b-button class="submit" variant="primary" v-b-modal.add-shift
            >Dodaj zmianę</b-button
          >
        </b-card>
      </b-col>
    </b-row>
    <b-modal
      id="add-shift"
      title="Dodaj nową zmianę"
      ok-title="Prześlij"
      cancel-title="Anuluj"
      centered
      @ok="addNewShift"
    >
      <b-form-group id="date-1" label="Wybierz dzień" label-for="input-1">
        <b-form-datepicker
          id="input-1"
          locale="pl"
          placeholder="Kliknij aby wybrać datę."
          :start-weekday=1
          v-model="selectedDay"
        />
      </b-form-group>
      <b-form-group id="date-1" label="Wybierz zmianę" label-for="input-2">
        <b-form-select v-model="selected" :options="options" />
      </b-form-group>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      itemId: null,
      selected: null,
      selectedDay: null,
      weekDay: 1,
      options: [
        { value: "Dzień", text: "Dzień" },
        { value: "Noc", text: "Noc" },
        { value: "Dzień rezerwa", text: "Dzień rezerwa" },
        { value: "Noc rezerwa", text: "Noc rezerwa" },
      ],
    };
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    loadData() {
      this.itemId = this.$route.params.id;
    },
    deleteShift(day, month, year, shift) {
      let id = this.$route.params.id;
      this.$bvModal
        .msgBoxConfirm(
          `Potwierdź usunięcie zmiany ${shift} w dniu ${day}.${month}.${year}`,
          {
            title: "Usuwanie zmiany",
            centered: true,
            okTitle: "Potwierdzam",
            okVariant: "danger",
            cancelTitle: "Anuluj",
          }
        )
        .then((value) => {
          if (value) {
            this.$store.dispatch("removeSingleShift", { day: day, index: id });
          }
        })
        .catch((error) => console.log(error));
    },
    async addNewShift() {
      let checkWeekend = new Date(this.selectedDay);
      let isWeekend =
        checkWeekend.getDay() === 0 || checkWeekend.getDay() === 6
          ? true
          : false;
      let newDate = this.selectedDay.split("-");
      let year = parseInt(newDate[0]);
      let month = parseInt(newDate[1]);
      let day = parseInt(newDate[2]);
      let data = this.getSingleId;
      if (this.selected && this.selectedDay) {
        await this.$store
          .dispatch("addSingleShift", {
            id: data.id,
            newDate: [
              ...data.attributes.selectedDays,
              {
                day: day,
                month: month,
                year: year,
                shiftOption: this.selected,
                isWeekend: isWeekend,
              },
            ],
            addedDate: {
              day: day,
              month: month,
              year: year,
              shiftOption: this.selected,
              isWeekend: isWeekend,
            },
          })
          .then((res) =>
            this.$bvToast.toast("Zmiana została dodana pomyślnie.", {
              title: "Sukces!",
              solid: true,
              appendToast: true,
            })
          )
          .catch((err) => {
            console.log(err)
            this.$bvModal.msgBoxOk(
              "Nie udało się dodać zmiany, spróbuj ponownie później",
              {
                okVariant: "danger",
                title: "Wystąpił błąd",
                centered: true,
              }
            );
          });
        this.selectedDay = null;
        this.selected = null;
      } else {
        console.log("błąd");
      }
    },
    disableDates(ymd, date) {
      let dayObj = new Date(date);
      let singleDay = dayObj.getDate();
      let month = dayObj.getMonth() + 1;
      let year = dayObj.getFullYear();
      let object = this.getSingleId.attributes.selectedDays;
      let checkForDate = object.find(
        (el) => el.day === singleDay && el.month === month && el.year === year
      );
      return checkForDate ? true : false;
    },
    removeSchedule(){
      const id = this.$route.params.id;
      this.$store.dispatch('removeSchedule', id).then((res) => this.goBack())
    }
  },
  computed: {
    getSingleId() {
      let fetchedData = this.$store.getters["getUserFetch"];
      const id = parseInt(this.$route.params.id)
      let item = fetchedData.schedules.find(
        (element) => element.id === id
      );
      
      return item;
    },
    sortDates() {
      return this.getSingleId.attributes.selectedDays.sort(function (a, b) {
        return a.day - b.day;
      });
    },
    returnPhone() {
      return `sms:${this.getSingleId.attributes.phone}&body=Witaj, `;
    },
    formatDate() {
      let date = new Date(this.getSingleId.attributes.createdAt);
      return `${date.getDate()}.${
        date.getMonth() + 1
      }.${date.getFullYear()} - ${date.getHours()}:${
        date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()
      }`;
    },
    returnMonday() {
      return 1;
    },
  },
  created() {
    this.loadData();
  },
};
</script>

<style scoped>
.bord {
  border: 1px solid rgb(231, 231, 231);
  margin: 5px 0;
}
p{
  margin-bottom: 0;
}
.submit{
  float: right;
  margin-top: 20px;
}
.delete {
  cursor: pointer;
}
.singleschedule {
  width: 95%;
}
.shift-mode {
  background: #4e73df;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
}
.header-section {
  display: flex;
  justify-content: space-between;
}
.header-back {
  cursor: pointer;
}
.card {
  border-radius: 0.8rem;
}
.card-header {
  border-radius: 0.8rem 0.8rem 0 0 !important;
}
.singleschedule a {
  text-decoration: none;
  color: #212529;
}
</style>
