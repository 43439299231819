<template>
  <b-tabs id="tab-container" pills content-class="mt-3" align="center">
    <b-tab title="Wyniki dzienne" active>
      <b-row class="align-items-center input-row">
        <b-col md="4">
          <label for="start-date">Początek okresu</label>
          <b-form-datepicker
            :start-weekday="1"
            v-model="startDate"
            id="start-date"
          />
        </b-col>
        <b-col md="4">
          <label for="start-date">Koniec okresu</label>
          <b-form-datepicker
            :start-weekday="1"
            v-model="endDate"
            id="end-date"
          />
        </b-col>
        <b-col md="4" class="filter">
          <b-button @click="changeFilter" class="w-100" variant="primary"
            >Zastosuj</b-button
          >
        </b-col>
      </b-row>
      <div class="mt-5 text-center" v-if="startDate && endDate && !isFetched">
        Brak danych w wybranym okresie.
      </div>

      <div v-if="isFetched && getMaxValues.totalEarning" class="mt-5">
        <!-- <b-form-group label="Wybierz elementy do wyświetlenia">
          <b-form-checkbox-group v-model="selected">
            <b-form-checkbox
              v-for="el in currentSelected"
              :key="el.name"
              :value="el.name"
              >{{ el.label }}</b-form-checkbox
            >
          </b-form-checkbox-group>
        </b-form-group> -->

        <b-row class="box-row">
          <b-col md="5" class="result-box p-4">
            <h3>Zarobek</h3>
            <div><strong>Zarobek NET:</strong> {{getMaxValues.totalEarning.toFixed(2)}} zł</div>
            <div><strong>Gotówka suma:</strong> {{getMaxValues.returnTotalCash.toFixed(2)}} zł</div>
            <div><strong>Zarobek dzień:</strong> {{getMaxValues.totalEarningDay.toFixed(2)}} zł</div>
            <div><strong>Zarobek noc:</strong> {{getMaxValues.totalEarningNight.toFixed(2)}} zł</div>
          </b-col>
          <b-col md="5" class="result-box p-4">
            <h3>Kilometry</h3>
            <div><strong>Przejechane KM:</strong> {{getMaxValues.totalKM}} km</div>
            <div><strong>Przejechane KM dzień:</strong> {{getMaxValues.totalKMDay}} km</div>
            <div><strong>Przejechane KM noc:</strong> {{getMaxValues.totalKMNight}} km</div>
            <div><strong>Zarobek / km:</strong> {{(getMaxValues.totalEarning / getMaxValues.totalKM).toFixed(2)}} zł / km</div>
            <div><strong>Zarobek dzień / km:</strong> {{(getMaxValues.totalEarningDay / getMaxValues.totalKMDay).toFixed(2)}} zł / km</div>
            <div><strong>Zarobek noc / km:</strong> {{(getMaxValues.totalEarningNight / getMaxValues.totalKMNight).toFixed(2)}} zł / km</div>
          </b-col>
          <b col md="12">
                <b-table id="results" striped  responsive :items="returnDailyResults" :fields="fields">
                </b-table>
          </b>
        </b-row>
      </div>
    </b-tab>
    <b-tab title="Wyniki tygodniowe"> </b-tab>
    <b-tab title="Podgląd indywidualny"> </b-tab>
  </b-tabs>
</template>

<script>
export default {
  data() {
    return {
      shiftsResults: null,
      startDate: null,
      endDate: null,
      isFetched: false,
      isLoading: false,
      fields: [
          {
            key: 'first_name',
            sortable: true,
            label: "Imię"
          },
          {
            key: 'last_name',
            sortable: true,
            label: "Nazwisko"
          },
          {
            key: 'date',
            sortable: true,
            label: "Dzień"
          },
          {
            key: 'shift',
            sortable: true,
            label: "Zmiana",
            formatter: (value, key, item) => {
              return value === "day" ? "D" : "N";
            },
          },
          {
            key: 'bolt_net',
            label: 'Bolt N',
            sortable: true,
            variant: 'success',
            // Variant applies to the whole column, including the header and footer
            formatter: (value, key, item) => {
              return value ? value.toFixed(2) : 0;
            },
          },
          {
            key: 'bolt_cash',
            label: 'Bolt G',
            sortable: true,
            variant: 'success',

            // Variant applies to the whole column, including the header and footer
            formatter: (value, key, item) => {
              return value ? value.toFixed(2) : 0;
            },
          },
          {
            key: 'uber_net',
            label: 'Uber N',
            sortable: true,
            // Variant applies to the whole column, including the header and footer
            formatter: (value, key, item) => {
              return value ? value.toFixed(2) : 0;
            },
          },
          {
            key: 'uber_cash',
            label: 'Uber G',
            sortable: true,
            // Variant applies to the whole column, including the header and footer
            formatter: (value, key, item) => {
              return value ? value.toFixed(2) : 0;
            },
          },
          {
            key: 'freeenow_net',
            label: 'FN N',
            sortable: true,
            variant: "danger",
            // Variant applies to the whole column, including the header and footer
            formatter: (value, key, item) => {
              return value ? value.toFixed(2) : 0;
            },
          },
          {
            key: 'freenow_cash',
            label: 'FN G',
            sortable: true,
            variant: "danger",
            // Variant applies to the whole column, including the header and footer
            formatter: (value, key, item) => {
              return value ? value.toFixed(2) : 0;
            },
          },
          {
            key: 'regPlate',
            label: 'Rej.',
            sortable: true,
            // Variant applies to the whole column, including the header and footer
            formatter: (value, key, item) => {
              return value ? value : "Brak";
            },
          },
          {
            key: 'totalMileage',
            label: 'KM',
            sortable: true,
            // Variant applies to the whole column, including the header and footer
            formatter: (value, key, item) => {
              return value ? value : 0;
            },
          },
          {
            key: 'perKm',
            label: '1 km',
            sortable: true,
            // Variant applies to the whole column, including the header and footer
            formatter: (value, key, item) => {
              return value ? value : 0;
            },
          },
          {
            key: 'totalEarning',
            label: 'Zarobek suma',
            sortable: true,
            // Variant applies to the whole column, including the header and footer
            formatter: (value, key, item) => {
              return value ? value.toFixed(2) : 0;
            },
          },
        ],
    };
  },
  methods: {
    async fetchDaysPeriod() {
      this.isLoading = true;
      await this.$store
        .dispatch("getDailyResults", {
          startDate: this.startDate,
          endDate: this.endDate,
        })
        .then((data) => {
          this.isFetched = true;
        });
      this.isLoading = false;
    },
    changeFilter() {
      this.fetchDaysPeriod();
    },
  },
  computed: {
    returnDailyResults() {
      return !this.isLoading && this.$store.getters["getDailyResults"];
    },
    getMaxValues() {
      const informations = {
        totalKM: 0,
        totalKMDay: 0,
        totalKMNight: 0,
        totalEarning: 0,
        totalEarningDay: 0,
        totalEarningNight: 0,
        totalRidesDay: 0,
        totalRidesNight: 0,
        totalRides: 0,
        avgPerKmDay: 0,
        avgPerKmNight: 0,
        avgPerKmTotal: 0,
        returnTotalCash: 0,
        totalCashDay: 0,
        totalCashNight: 0,
        boltRidesDay: 0,
        boltRidesNight: 0,
        boltRidesTotal: 0,
        shiftOnCars: []
      };

      for (let i = 0; i < this.returnDailyResults.length; i++) {
        const {
          bolt_cash,
          bolt_gross,
          bolt_net,
          freenow_net,
          freenow_cash,
          freenow_rides_number,
          perKm,
          regPlate,
          rides_number,
          totalEarning,
          shift,
          totalMileage,
          uber_cash,
          uber_net,
          uber_rides_number,
        } = this.returnDailyResults[i];
        if(shift === "day"){
          informations.totalKMDay += totalMileage;
          informations.totalEarningDay += totalEarning
          informations.totalCashDay += bolt_cash + freenow_cash + uber_cash;
          informations.boltRidesDay += rides_number
          informations.totalRidesDay += (uber_rides_number ?? 0) + (rides_number ?? 0) + (freenow_rides_number ?? 0)
        }
        if(shift === "night"){
          informations.totalKMNight += totalMileage;
          informations.totalEarningNight += totalEarning
          informations.totalCashNight += bolt_cash + freenow_cash + uber_cash;

          informations.boltRidesNight += rides_number
          informations.totalRidesNight += (uber_rides_number ?? 0) + (rides_number ?? 0) + (freenow_rides_number ?? 0)
        }
        informations.totalKM += totalMileage;
        informations.boltRidesTotal += rides_number;
        informations.totalEarning += totalEarning;
        informations.returnTotalCash += bolt_cash + freenow_cash + uber_cash;
        informations.totalRides += (uber_rides_number ?? 0) + (rides_number ?? 0) + (freenow_rides_number ?? 0)


      }
      return !this.isLoading && informations;
    },
  },
  watch: {},
};
</script>

<style scoped>
.vehtable {
  text-align: center;
}
.infobox {
  background-color: #3a3a3a;
  border-radius: 15px;
  color: #fff;
  padding: 20px;
}
#tab-container {
  width: 80%;
  background-color: #fff;
  border-radius: 20px;
  padding: 20px;
}
.filter {
  margin-top: auto;
}
.result-box {
  background-color: #2e59d9;
  border-radius: 16px;
  color: #fff;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 8px;
}
.total {
  font-weight: 700;
}

.prop {
  font-size: 1.2em;
  background: #fff;
  color: black;
  margin: 8px 0;
  padding: 8px 16px;
  border-radius: 8px;
}
.prop-driver {
  font-size: 1em;
  background: #fff;
  color: black;
  margin: 8px 0;
  padding: 8px 16px;
  border-radius: 8px;
}
.box-row {
  display: flex;
  justify-content: space-between;
  gap: 20px 0;
}
.percentage {
  margin-left: 8px;
  color: #00dfa2;
}
.more {
  cursor: pointer;
}
.good {
  margin-left: 4px;
  color: #00dfa2;
}
.bad {
  margin-left: 4px;
  color: #df2100;
}
.other-driver {
  border: 1px solid black;
  padding: 16px;
  border-radius: 8px;
  margin: 8px 0;
  text-align: center;
}
.selected {
  background-color: #00dfa2;
}
.single-day {
  padding: 16px 4px;
  border: 1px solid black;
  border-radius: 8px;
  margin: 8px 0;
}
.single-entry {
  padding: 8px 0;
}

</style>
