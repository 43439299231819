<template>
  <b-tabs id="tab-container" pills content-class="mt-3" align="center">
    <b-tab title="Lista bonusów" active>
      <div class="bonus-button">
        <b-button
          class="float-right"
          @click="toggleModal"
          variant="outline-primary"
          >Dodaj bonus</b-button
        >
      </div>
      <b-table
        :items="returnBonuses"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
      ></b-table>
    </b-tab>

    <b-modal
      size="lg"
      centered
      ok-title="Dodaj"
      cancel-title="Anuluj"
      ref="add-bonus"
      title="Dodaj nowy bonus"
      @ok="addBonus"
    >
      <b-form-group label="Data bonusu">
        <b-form-datepicker
          v-model="bonus.date"
          placeholder="Wybierz datę bonusu"
        />
      </b-form-group>
      <b-form-group label="Wybierz zmianę dla bonusu">
        <b-form-select v-model="bonus.shift" :options="options">
        </b-form-select>
      </b-form-group>
      <b-form-group label="Kwota bonusu">
        <b-form-input placeholder="20" type="number" v-model="bonus.value">
        </b-form-input>
      </b-form-group>
    </b-modal>
  </b-tabs>
</template>

<script>
export default {
  data() {
    return {
      savedBonuses: null,
      notCompleted: null,
      isLoading: false,
      bonus: {
        date: null,
        shift: null,
        value: null,
      },
      options: [
        { value: null, text: "Wybierz zmianę" },
        { value: "Dzień", text: "Dzień" },
        { value: "Noc", text: "Noc" },
      ],
      sortBy: "date",
      sortDesc: true,

      fields: [
        { key: "date", label: "Data", sortable: true },
        { key: "shift", label: "Zmiana" },
        { key: "value", label: "PLN" },
      ],
    };
  },
  created() {
    this.getBonuses();
  },
  methods: {
    toggleModal() {
      this.$refs["add-bonus"].toggle("#toggle-modal");
    },
    addBonus() {
      if (this.bonus.date && this.bonus.shift && this.bonus.value) {
        console.log("firing");
        this.$store.dispatch("addBonus", this.bonus);
      } else {
        this.notCompleted = true;
      }
    },
    getBonuses() {
      this.isLoading = true;
      this.$store.dispatch("getBonuses");
      this.isLoading = false;
    },
  },
  computed: {
    returnBonuses() {
      return !this.isLoading && this.$store.getters["getListOfBonus"];
    },
  },
};
</script>

<style scoped>
#tab-container {
  width: 80%;
  background-color: #fff;
  border-radius: 20px;
  padding: 20px;
}
</style>
