<template>
            <b-nav
          class="admin-header d-flex align-content-center justify-content-end"
        >
      <b-navbar-toggle target="sidebar-admin" class="mobile-toggler">
      <template #default="{ expanded }">
        <b-icon variant="primary" v-if="expanded" icon="chevron-bar-up"></b-icon>
        <b-icon variant="primary" v-else icon="three-dots"></b-icon>
      </template>
    </b-navbar-toggle>
          <b-nav-item-dropdown id="my-nav-dropdown" no-caret right>
            <template #button-content>
              <b-icon icon="bell" />
              <b-badge class="badge" variant="danger">1</b-badge>
            </template>
            <b-dropdown-item disabled class="notification-title" >Powiadomienia</b-dropdown-item>
            <b-dropdown-divider class="notification-header"/>
            <b-dropdown-divider/>
            <b-dropdown-item to="/admin/edycja-grafiku">Edycja grafiku</b-dropdown-item>
            <b-dropdown-item to="/admin/miesiac">Ustawienia</b-dropdown-item>
          </b-nav-item-dropdown>

          <p class="separator" />
          <b-nav-item><b-icon icon="box-arrow-right" @click="logOut"/></b-nav-item>
        </b-nav>
</template>

<script>
export default {

  methods :{
    logOut(){
      this.$store.dispatch('logOut').then(this.$router.push('/'))
    }
  }
}
</script>

<style scoped>
.admin-not {
  margin-left: -25px;
}

.admin-header {
  background-color: #f8f9fc !important;
  flex: 1;
}
.admin-header {
  background-color: #fff;
  height: 3.375rem;
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15) !important;
}
.separator {
  width: 2px;
  border-left: 2px solid rgb(180, 180, 180);
  margin: 10px 5px;
}
.mobile-toggler{
  margin-right: auto;
}
.badge {
  background-color: red;
  color: #fff;
  position: relative;
  transform: scale(0.7);
  transform-origin: top right;
  right: 0.15rem;
  margin-left: -10px;
  margin-top: -0.35rem;
}
.notification-header .dropdown-divider{
  background-color: black;
  margin: 0 0 10px 0px;
}

</style>
