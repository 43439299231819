<template>
  <b-row class="h-100 mobile">
    <b-col class="left d-flex" md="3">
      <div class="contain d-flex flex-column justify-content-between">
        <div class="title">AutoGrafik</div>
        <div class="content">
          <b-icon scale="2.8" icon="chat-quote" />
          <div class="content-text">
            <strong>Funkcjonalność systemu</strong>
            <p class="small">
              AutoGrafik jest systemem, do którego ciągle dodawane są nowe
              funkcje. W razie dodatkowych propozycji zachęcamy do kontaktu.
            </p>
          </div>
        </div>
        <div class="contact">
          <a href="mailto:pomoc@rightcloud.pl"
            ><b-icon class="mr-2" icon="chat" />Wsparcie techniczne</a
          >
        </div>
      </div>
    </b-col>
    <b-col
      class="right d-flex justify-content-center align-items-center"
      md="9"
    >
      <div class="login">
        <h3>Zaloguj się</h3>
        <p>Do panelu administracyjnego</p>
        <b-alert v-model="failedLogin" variant="danger" dismissible>
          Błędny login lub hasło!
        </b-alert>
        <b-input-group class="mb-2">
          <template #prepend>
            <b-input-group-text
              ><b-icon
                icon="person-circle
"
            /></b-input-group-text>
          </template>
          <b-form-input
            placeholder="Adres e-mail / login"
            v-model="form.login"
          />
        </b-input-group>
        <b-input-group class="mb-3">
          <template #prepend>
            <b-input-group-text><b-icon icon="key"/></b-input-group-text>
          </template>
          <b-form-input
            type="password"
            v-model="form.password"
            placeholder="Hasło"
          />
        </b-input-group>
        <div class="button">
          <b-button @click="login" variant="primary">Zaloguj się </b-button>
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
export default {
  data() {
    return {
      user: "",
      form: { login: null, password: null },
      failedLogin: false,
    };
  },
  methods: {
    login() {
      if (this.form.login && this.form.password) {
        this.$store
          .dispatch("login", this.form)
          .then((res) => {
            this.$router.push("/admin");
          })
          .catch((err) => (this.failedLogin = true));
      }
    },
    // async autoLogin() {
    //   const jwt = localStorage.getItem("identifier");
    //   if(jwt){
    //   await this.$store.dispatch("autoLogin").then((res) => {
    //     const lastPage = this.$router.history._startLocation;
    //     this.$router.push("admin");
    //   });
    //   }
    // },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css?family=Raleway:400,700");
a {
  text-decoration: none;
  color: rgb(255, 247, 247);
}
a:hover {
  text-decoration: none;
  color: #fff;
}
.login {
  flex-basis: 35%;
}
.left {
  background-color: #4e73df;
}
.button {
  text-align: right;
}
.title {
  color: #fff;
  font-weight: 700;
  font-size: 1.4em;
}
.contain {
  margin-left: 10px;
}
.content {
  color: #fff;
  text-align: center;
}
.contact {
  text-align: center;
  margin-bottom: 10px;
}

.content-text {
  margin: 30px 0;
  font-size: 1.1em;
}
@media (max-width: 800px) {
  .login {
    flex-basis: 80%;
  }
  .mobile .left {
    display: none !important;
  }
}
</style>
