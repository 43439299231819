<template>
  <div class="shift-box">
    <div class="head">
      <!-- do poprawki jako osobny komponent -> przenieśc do ShiftsNum -->
      <p class="date">Przesłano dnia {{ formatDate }}</p>
      <b-avatar class="avt" variant="primary"></b-avatar>
      <span class="name">{{ name }}</span>
    </div>
    <div class="badge-zone">
      <b-badge variant="primary" class="total">Ilość zmian: {{ shiftNum.total }}</b-badge>
      <b-badge variant="warning" v-if="shiftNum.day > 0">Dzień: {{ shiftNum.day }}</b-badge>
      <b-badge variant="dark" v-if="shiftNum.night > 0"
        >Noc: {{ shiftNum.night }}</b-badge
      >
      <b-badge variant="info" v-if="shiftNum.reserveDay > 0"
        >Rezerwa dzień: {{ shiftNum.reserveDay }}</b-badge
      >
      <b-badge variant="info" v-if="shiftNum.reserveNight > 0">
        Rezerwa noc: {{ shiftNum.reserveNight }}</b-badge
      >
    </div>
  </div>
</template>

<script>
export default {
  props: ["shifts", "name", "date"],
  computed: {
    shiftNum() {
      const shifts = this.shifts;
      const numbers = { day: 0, night: 0, reserveDay: 0, reserveNight: 0 };
      for (let index in shifts) {
        if (shifts[index].shiftOption === "Dzień") {
          numbers.day++;
        }
        if (shifts[index].shiftOption === "Noc") {
          numbers.night++;
        }
        if (shifts[index].shiftOption === "Noc rezerwa") {
          numbers.reserveNight++;
        }
        if (shifts[index].shiftOption === "Dzień rezerwa") {
          numbers.reserveDay++;
        }
      }
      return {
        ...numbers,
        total:
          numbers.day +
          numbers.night +
          numbers.reserveDay +
          numbers.reserveNight,
      };
    },
    formatDate(){
        let date = new Date(this.date)
        return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()} - ${date.getHours()}:${date.getMinutes() < 10? `0${date.getMinutes()}` : date.getMinutes()  }`
    }
  },
};
</script>

<style scoped>
.badge-zone {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.badge-zone .badge {
  padding: 10px;
}
.total, .avt {
    border: 1px solid #fff;
}
.shift-box{
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.head .name{
    margin-left: 5px;
}
.head .date{
  font-size: .7em
}
@media (max-width: 600px){
    .shift-box{
        flex-direction: column;
        gap: 10px;
        text-align: center;
    }
    .avt{
        display: none;
    }
    .head .name{
        margin: 0;
        font-weight: 600;
    }

}
</style>
