<template>
    <div class="admin-page">
      <admin-sidebar :toggled="sidebarMobile" @hide="toggleSidebar(false)"/>
      <div class="page-wrapper">
      <admin-header @change="toggleSidebar"/>
          <div class="page-content">
          <router-view/>
          </div>
      </div>
    </div>
</template>

<script>
import adminSidebar from "../components/AdminSidebar.vue";
import adminHeader from "../components/AdminHeader.vue";
export default {
  name: 'AdminPanel',
  components: { adminHeader, adminSidebar },
  data() {
    return {
      visibleNot: false,
      sidebarMobile: false,
    };
  },
  methods: {
    toggleSidebar(val){
      console.log(val)
}
  }
};
</script>

<style scoped>
.admin-page {
  background-color: #f8f9fc;
  height: 100%;
  min-width: 300px;
}
.page-wrapper {
  width: 100%;
  background-color: #f8f9fc;
  min-height: 100%;
  padding-bottom: 4em;
}
.page-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 60px 40px;
  margin-bottom: auto;
}
@media (max-width:700px){
  .page-content{
    margin: 30px 10px;
  }
}

</style>
