<template>
  <b-modal
    id="pin"
    title="Wprowadź dane aby uzyskać dostęp"
    centered
    hide-header-close
    hide-footer
    no-close-on-esc
    no-close-on-backdrop
    ok-title="Przejdź dalej"
    header-class="pin-title"
  >

  <div class="phone-input">
    <b-alert v-model="pinNotValid" variant="danger" dismissible>Wprowadziłeś niepoprawne dane. Popraw numer telefonu oraz PIN i spróbuj ponownie.</b-alert>
    <b-row class="mb-3">
      <b-col sm="4" class="phone-label">
        <label for="phone">Numer telefonu</label>
      </b-col>
      <b-col sm="8">
        <b-form-input type="number" v-model="phone"></b-form-input>
            <b-form-text id="input-live-help">Wprowadź numer bez prefiksu +48.</b-form-text>
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col sm="4" class="phone-label">
        <label for="phone">Kod PIN</label>
      </b-col>
      <b-col sm="8">
        <b-form-input v-model="pinCode" type="number"></b-form-input>
      </b-col>
    </b-row>
  </div>

  <div class="button-zone d-flex justify-content-between" >
    <h4 @click="validate" class="pointer">Dalej<b-icon icon="caret-right" class="ic"/></h4>
  </div>
  
  </b-modal>
</template>

<script>
export default {
  data(){
    return{
      firstNum: null,
      secondNum: null,
      thirdNum: null,
      fourthNum: null,
      pinValid: null,
      pinNotValid: false,
      pinCode: null,
      phone: null,
    }
  },
  mounted(){
    this.$root.$emit('bv::show::modal', 'pin', '#input-1')
    this.autoLogin()
  },
    methods: {
      async validate(){        
        localStorage.setItem('phone', this.phone)
        localStorage.setItem('pinCode', this.pinCode)
          await this.$store.dispatch('checkPinAndPhone', {pin: this.pinCode, phone: this.phone}).then((res) => {
            if(!this.$store.state.validPin){
              localStorage.removeItem("phone");
              localStorage.removeItem("pinCode");
              this.pinNotValid = true;
            }
          }).catch(err => console.log(err))
      },
      async autoLogin(){
          const pin = localStorage.getItem('pinCode')
          const phone = localStorage.getItem('phone')
          if(pin && phone){
          await this.$store.dispatch('checkPinAndPhone', {pin: pin, phone: phone})
          }
      }
    },
}
</script>


<style>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
#pin.modal.fade {
  background-color: rgba(21, 58, 103, 0.1) !important;
}
.pin-title{
   
    display: block;
    justify-content: center !important;
    border-bottom: 1px solid black;;
}
.input-zone{
    display: flex;
    align-content: center;
    justify-content: center;
}
.single-pin {
    min-width: 45px;
    height: 60px;
    margin: 10px 14px;
}
.single-pin {
    font-size: 2em
}
.button-zone{
float: right;
}
.pointer{
    cursor: pointer;
}
.ic{
  margin: 0 10px;
}
.phone-label{
  display: flex;
  align-items: center;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.input-zone input[type=number] {
  -moz-appearance: textfield;
  text-align: center;
}
</style>