<template>
  <b-tabs id="tab-container" pills content-class="mt-3" align="center">
    <b-tab title="Podgląd sumaryczny" active>
      <b-row class="align-items-center input-row">
        <b-col md="4">
          <label for="start-date">Początek okresu</label>
          <b-form-datepicker
            :start-weekday="1"
            v-model="startDate"
            id="start-date"
          />
        </b-col>
        <b-col md="4">
          <label for="start-date">Koniec okresu</label>
          <b-form-datepicker
            :start-weekday="1"
            v-model="endDate"
            id="end-date"
          />
        </b-col>
        <b-col md="4" class="filter">
          <b-button @click="changeFilter" class="w-100" variant="primary"
            >Zastosuj</b-button
          >
        </b-col>
      </b-row>
      <div class="mt-5 text-center" v-if="startDate && endDate && !isFetched">
        Brak danych w wybranym okresie.
      </div>
      <b-dropdown
        v-if="isFetched"
        class="my-2"
        id="filter"
        text="Elementy do wyświetlenia"
      >
        <b-form-checkbox-group class="p-3" v-model="selected">
          <b-form-checkbox
            v-for="el in currentSelected"
            class="my-1"
            :key="el.name"
            :value="el.name"
            >{{ el.label }}</b-form-checkbox
          >
        </b-form-checkbox-group>
      </b-dropdown>
      <div v-if="isFetched">
        <!-- <b-form-group label="Wybierz elementy do wyświetlenia">
          <b-form-checkbox-group v-model="selected">
            <b-form-checkbox
              v-for="el in currentSelected"
              :key="el.name"
              :value="el.name"
              >{{ el.label }}</b-form-checkbox
            >
          </b-form-checkbox-group>
        </b-form-group> -->
        <apexchart
          class="mt-5"
          :options="getAllFromFiltered.options"
          :series="getAllFromFiltered.series"
          v-if="currentSelected[10].visible && returnCurrentUser.admin"
        />
        <b-row class="box-row">
          <b-col
            md="5"
            v-if="currentSelected[0].visible"
            class="result-box p-4"
          >
            <h3>Kilometry</h3>
            <div class="content">
              <div class="prop">
                Suma dzień KM:
                <strong>{{ getAllFromFiltered.totalDayKm }} </strong>km
              </div>
              <div class="prop">
                Suma noc KM:
                <strong>{{ getAllFromFiltered.totalNightKm }} </strong>km
              </div>
              <div class="prop">
                Śr. Dzień:
                <strong>{{
                  (
                    getAllFromFiltered.totalDayKm /
                    getAllFromFiltered.totalDaysWithMileage
                  ).toFixed(2)
                }}</strong>
                km
              </div>
              <div class="prop">
                Śr. Noc:
                <strong>{{
                  (
                    getAllFromFiltered.totalNightKm /
                    getAllFromFiltered.totalNightsWithMileage
                  ).toFixed(2)
                }}</strong>
                km
              </div>
              <div class="prop">
                Śr. km / dzień:
                <strong>{{ returnAvgPerKm.dayPerKm }}</strong> PLN
              </div>
              <div class="prop">
                Śr. km / noc:
                <strong>{{ returnAvgPerKm.nightPerKm }}</strong> PLN
              </div>
            </div>
          </b-col>
          <b-col
            md="5"
            v-if="currentSelected[1].visible"
            class="result-box p-4"
          >
            <h3>Aplikacje + śr. wyniki</h3>
            <div class="content">
              <div class="prop">
                Przejazdy Bolt:
                <strong>{{ returnAvgPerKm.totalBoltRides }}</strong>
                <span class="percentage"
                  >{{
                    (
                      (returnAvgPerKm.totalBoltRides /
                        returnAvgPerKm.totalRides) *
                      100
                    ).toFixed(2)
                  }}%</span
                >
              </div>
              <div class="prop">
                Przejazdy Uber:
                <strong>{{ returnAvgPerKm.totalUberRides }}</strong>
                <span class="percentage"
                  >{{
                    (
                      (returnAvgPerKm.totalUberRides /
                        returnAvgPerKm.totalRides) *
                      100
                    ).toFixed(2)
                  }}%</span
                >
              </div>
              <div class="prop">
                Przejazdy FreeNow:
                <strong>{{ returnAvgPerKm.totalFnRides }}</strong
                ><span class="percentage"
                  >{{
                    (
                      (returnAvgPerKm.totalFnRides /
                        returnAvgPerKm.totalRides) *
                      100
                    ).toFixed(2)
                  }}%</span
                >
              </div>
              <div class="prop">
                Suma przejazdów:
                <strong>{{ returnAvgPerKm.totalRides }}</strong>
              </div>
              <div class="prop">
                Średni wynik dzień:
                <strong>{{
                  (
                    returnAvgPerKm.dayAvgResult.income /
                    returnAvgPerKm.dayAvgResult.shifts
                  ).toFixed(2)
                }}</strong>
                PLN
              </div>
              <div class="prop">
                Średni wynik noc:
                <strong>{{
                  (
                    returnAvgPerKm.nightAvgResult.income /
                    returnAvgPerKm.nightAvgResult.shifts
                  ).toFixed(2)
                }}</strong>
                PLN
              </div>
            </div>
          </b-col>
          <b-col
            md="5"
            v-if="currentSelected[2].visible"
            class="result-box p-4"
          >
            <h3>Kierowcy</h3>
            <div class="content">
              <div class="prop">
                Zmiany poniżej śr.: <strong>{{ returnTop5.belowAvg }}</strong>
              </div>
              <div class="prop">
                Zmiany powyżej śr.: <strong>{{ returnTop5.upAvg }}</strong>
              </div>
            </div>
          </b-col>
          <b-col
            md="5"
            v-if="currentSelected[3].visible && returnCurrentUser.admin"
            class="result-box p-4"
          >
            <h3>Rentowność</h3>
            <div class="content">
              <div class="prop">
                Śr. rentowność dzień:
                <strong>{{
                  (
                    returnAvgPerKm.dayAvgResult.companyIncome /
                    returnAvgPerKm.dayAvgResult.shifts
                  ).toFixed(2)
                }}</strong>
                PLN
              </div>
              <div class="prop">
                Śr. rentowność noc:
                <strong>{{
                  (
                    returnAvgPerKm.nightAvgResult.companyIncome /
                    returnAvgPerKm.nightAvgResult.shifts
                  ).toFixed(2)
                }}</strong>
                PLN
              </div>
              <div class="prop">
                Dzień suma:
                <strong>{{
                  returnAvgPerKm.dayAvgResult.companyIncome.toFixed(2)
                }}</strong>
                PLN
              </div>
              <div class="prop">
                Noc suma:
                <strong>{{
                  returnAvgPerKm.nightAvgResult.companyIncome.toFixed(2)
                }}</strong>
                PLN
              </div>
            </div>
          </b-col>
          <b-col
            md="5"
            v-if="currentSelected[4].visible"
            class="result-box p-4"
          >
            <h3>Najlepsze wyniki D</h3>
            <div class="content">
              <div
                v-if="returnTop5.top5"
                v-for="driver in returnTop5.top5"
                :key="driver.id"
                class="prop-driver"
              >
                <small>{{ driver.date }}</small
                ><br />
                {{ driver.full_name }} -
                <span class="total">{{ driver.total_income }} PLN</span>
                <br />
                <small>{{ driver.shift }}</small>
              </div>
            </div>
          </b-col>

          <b-col
            md="5"
            v-if="currentSelected[5].visible"
            class="result-box p-4"
          >
            <h3>Najgorsze wyniki D</h3>
            <div class="content">
              <div
                v-if="returnTop5.worst5"
                v-for="driver in returnTop5.worst5"
                :key="driver.id"
                class="prop-driver"
              >
                <small>{{ driver.date }}</small
                ><br />
                {{ driver.full_name }} -
                <span class="total">{{ driver.total_income }} PLN</span>
                <br />
                <small>{{ driver.shift }}</small>
              </div>
            </div>
          </b-col>
          <b-col
            md="5"
            v-if="currentSelected[6].visible"
            class="result-box p-4"
          >
            <h3>Najlepsze wyniki N</h3>
            <div class="content">
              <div
                v-if="returnTop5.topN5"
                v-for="driver in returnTop5.topN5"
                :key="driver.id"
                class="prop-driver"
              >
                <small>{{ driver.date }}</small
                ><br />
                {{ driver.full_name }} -
                <span class="total">{{ driver.total_income }} PLN</span>
                <br />
                <small>{{ driver.shift }}</small>
              </div>
            </div>
          </b-col>

          <b-col
            md="5"
            v-if="currentSelected[7].visible"
            class="result-box p-4"
          >
            <h3>Najgorsze wyniki N</h3>
            <div class="content">
              <div
                v-if="returnTop5.worstN5"
                v-for="driver in returnTop5.worstN5"
                :key="driver.id"
                class="prop-driver"
              >
                <small>{{ driver.date }}</small
                ><br />
                {{ driver.full_name }} -
                <span class="total">{{ driver.total_income }} PLN</span>
                <br />
                <small>{{ driver.shift }}</small>
              </div>
            </div>
          </b-col>
          <b-col
            md="5"
            v-if="currentSelected[8].visible"
            class="result-box p-4"
          >
            <h3>Najlepsze śr. wyniki</h3>
            <div class="content">
              <div
                v-if="returnTop5.top5Avg"
                v-for="driver in returnTop5.top5Avg"
                :key="driver.id"
                class="prop-driver"
              >
                <small>Ilość zmian: {{ driver.shifts }}</small
                ><br />
                {{ driver.full_name }} -
                <span class="total">{{ driver.avgPerShift }} PLN</span>
                <br />
                <small>Zarobek: {{ driver.total_income.toFixed(2) }} PLN</small>
              </div>
            </div>
          </b-col>
          <b-col
            md="5"
            v-if="currentSelected[9].visible"
            class="result-box p-4"
          >
            <h3>Najgorsze śr. wyniki</h3>
            <div class="content">
              <div
                v-if="returnTop5.worst5Avg"
                v-for="driver in returnTop5.worst5Avg"
                :key="driver.id"
                class="prop-driver"
              >
                <small>Ilość zmian: {{ driver.shifts }}</small
                ><br />
                {{ driver.full_name }} -
                <span class="total">{{ driver.avgPerShift }} PLN</span>
                <br />
                <small>Zarobek: {{ driver.total_income.toFixed(2) }} PLN</small>
              </div>
            </div>
          </b-col>
          <b-col
            md="12"
            v-if="currentSelected[11].visible"
            class="result-box p-4"
          >
            <h3>Jazda na aplikacjach</h3>
            <div class="content">
              <div
                class="prop mt-2"
                v-for="employee in returnAvgPerKm.employeeList"
                :key="employee.id"
              >
                {{ employee.full_name }}
                <b-progress
                  :max="
                    employee.uberRides + employee.fnRides + employee.boltRides
                  "
                  show-value
                >
                  <b-progress-bar
                    :value="employee.boltRides"
                    variant="success"
                  ></b-progress-bar>
                  <b-progress-bar
                    :value="employee.uberRides"
                    variant="dark"
                  ></b-progress-bar>
                  <b-progress-bar
                    :value="employee.fnRides"
                    variant="danger"
                  ></b-progress-bar>
                </b-progress>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-tab>
    <b-tab title="Podgląd okresu">
      <b-row class="align-items-center input-row mb-2">
        <b-col md="4">
          <label for="start-date">Początek okresu</label>
          <b-form-datepicker
            :start-weekday="1"
            v-model="startDate"
            id="start-date"
          />
        </b-col>
        <b-col md="4">
          <label for="start-date">Koniec okresu</label>
          <b-form-datepicker
            :start-weekday="1"
            v-model="endDate"
            id="end-date"
          />
        </b-col>
        <b-col md="4" class="filter">
          <b-button @click="changeFilter" class="w-100" variant="primary"
            >Zastosuj</b-button
          >
        </b-col>
      </b-row>
      <b-row v-if="returnSelectedPeriod">
        <b-col md="12">
          <div
            class="single-day"
            v-for="day in returnSelectedPeriod"
            :key="day.date"
          >
            <div class="day-title">
              <strong>{{ day.date }}</strong>
            </div>
            <b-row>
              <b-col md="6">
                Dzień
                <div class="single-entry" v-for="driver in day.dayShift">
                  {{ driver.full_name }} - Zarobek
                  <strong
                    ><br />{{ driver.total_income }} PLN |
                    {{ driver.driven_mileage }} km
                  </strong>
                <div class="avg-fuel">
                  Spalanie w dniu: {{getFuelFromSingleDay(driver)}}
                </div>
                <div class="avg-fuel">
                  Ilość godzin pracy: {{getWorkingHours(driver)}} 
                </div>
                </div>
              </b-col>
              <b-col md="6">
                Noc
                <div class="single-entry" v-for="driver in day.nightShift">
                  {{ driver.full_name }} - Zarobek
                  <strong
                    ><br />{{ driver.total_income }} PLN |
                    {{ driver.driven_mileage }} km</strong
                  >

                <div class="avg-fuel">
                  Spalanie w dniu: {{getFuelFromSingleDay(driver)}}
                </div>
                <div class="avg-fuel">
                  Ilość godzin pracy: {{getWorkingHours(driver)}} 
                </div>
                </div>

              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </b-tab>
    <b-tab title="Podgląd indywidualny">
      <b-alert v-if="startDate && endDate" variant="success" show
        >Wybrany okres: {{ startDate }} - {{ endDate }}</b-alert
      >
      <b-row class="align-items-center input-row mb-2">
        <b-col md="4">
          <label for="start-date">Początek okresu</label>
          <b-form-datepicker
            :start-weekday="1"
            v-model="startDate"
            id="start-date"
          />
        </b-col>
        <b-col md="4">
          <label for="start-date">Koniec okresu</label>
          <b-form-datepicker
            :start-weekday="1"
            v-model="endDate"
            id="end-date"
          />
        </b-col>
        <b-col md="4" class="filter">
          <b-button @click="changeFilter" class="w-100" variant="primary"
            >Zastosuj</b-button
          >
        </b-col>
      </b-row>
      <b-row class="box-row" v-if="isFetched">
        <b-col md="4">
          <label>Wybierz pracownika</label>
        </b-col>
        <b-col md="8">
          <b-form-select v-model="selectedPerson">
            <b-form-select-option
              v-for="person in returnAvgPerKm.employeeList"
              :value="person.id"
              :key="person.id"
              >{{ person.full_name }}</b-form-select-option
            >
          </b-form-select>
        </b-col>
      </b-row>
      <b-row v-if="selectedPerson" class="mt-5 box-row">
        <b-col md="5" class="result-box p-4">
          <h3>Informacje o kierowcy</h3>
          <div class="content">
            <div class="prop">
              Ilość zmian:
              <strong>{{ returnSinglePersonData.shiftsAmount }}</strong>
            </div>
            <div class="prop">
              Ilość dniówek:
              <strong>{{
                returnSinglePersonData.personShiftsDay.length
              }}</strong>
            </div>
            <div class="prop">
              Ilość nocek:
              <strong>{{
                returnSinglePersonData.personShiftsNight.length
              }}</strong>
            </div>
          </div>
        </b-col>
        <b-col md="5" class="result-box p-4">
          <h3>Wyniki</h3>
          <div class="content">
            <div class="prop">
              Śr. za zmianę:
              <strong>{{ returnSinglePersonData.avgIncomeShift }}</strong> PLN
            </div>
            <div class="prop">
              Najlepsza zmiana:
              <strong>{{
                returnSinglePersonData.bestShift.total_income
              }}</strong>
              PLN
              <span
                :class="
                  returnSinglePersonData.bestShift.total_income >
                  returnSinglePersonData.avgFromBestDay
                    ? 'good'
                    : 'bad'
                "
                >{{
                  (
                    ((returnSinglePersonData.bestShift.total_income -
                      returnSinglePersonData.avgFromBestDay) /
                      returnSinglePersonData.avgFromBestDay) *
                    100
                  ).toFixed(2)
                }}%</span
              >
              <br />
              <small
                >{{ returnSinglePersonData.bestShift.date }} /
                {{ returnSinglePersonData.bestShift.shift }}</small
              >
              <div
                class="float-right more"
                id="togglemodal"
                @click="toggleModal('day')"
              >
                <b-icon icon="people-fill" />
              </div>
            </div>
            <div class="prop">
              Najgorsza zmiana:
              <strong>{{
                returnSinglePersonData.worstShift.total_income
              }}</strong>
              PLN
              <span
                :class="
                  returnSinglePersonData.worstShift.total_income >
                  returnSinglePersonData.avgFromWorstDay
                    ? 'good'
                    : 'bad'
                "
                >{{
                  (
                    ((returnSinglePersonData.worstShift.total_income -
                      returnSinglePersonData.avgFromWorstDay) /
                      returnSinglePersonData.avgFromWorstDay) *
                    100
                  ).toFixed(2)
                }}%</span
              >
              <br />
              <small
                >{{ returnSinglePersonData.worstShift.date }} /
                {{ returnSinglePersonData.worstShift.shift }}</small
              >
              <div
                class="float-right more"
                id="togglemodal"
                @click="toggleModal('night')"
              >
                <b-icon icon="people-fill" />
              </div>
            </div>
          </div>
        </b-col>
        <b-col md="5" class="result-box p-4">
          <h3>Udział aplikacji</h3>
          <div class="content">
            <div class="prop">
              Przejazdy Bolt:
              <strong>{{ returnSinglePersonData.boltRides }}</strong>
            </div>
            <div class="prop">
              Przejazdy Uber:
              <strong>{{ returnSinglePersonData.uberRides }}</strong>
            </div>
            <div class="prop">
              Przejazdy FreeNow:
              <strong>{{ returnSinglePersonData.freeNowRides }}</strong>
            </div>
            <div class="prop">
              Przejazdy w sumie:
              <strong>{{ returnSinglePersonData.totalRides }}</strong>
            </div>
          </div>
        </b-col>
        <b-col md="5" class="result-box p-4">
          <h3>Spalanie i auto</h3>
          <div class="content">
            <div class="prop">
              Średnie spalanie:
              <strong>{{
                returnSinglePersonData.avgFuelCompsumtion.toFixed(2)
              }}</strong>
              l
            </div>
            <div class="prop">
              Średni koszt paliwa:
              <strong>{{
                returnSinglePersonData.avgTotalFuel.toFixed(2)
              }}</strong>
              PLN
            </div>
            <div class="prop">
              Przejechane KM:
              <strong>{{ returnSinglePersonData.totalMilage }}</strong> km
            </div>
            <div class="prop">
              Śr. KM / zmiana:
              <strong>{{ returnSinglePersonData.avgPerShift }}</strong> km
            </div>
          </div>
        </b-col>
      </b-row>
    </b-tab>
    <b-tab title="Import pliku">
      <b-alert variant="primary" v-if="this.isLoading" show
        >Plik ładuje się na serwer.</b-alert
      >
      <b-alert variant="primary" v-if="this.fileLoading" show
        >Uploadowanie pliku...</b-alert
      >

      <b-row class="align-items-center input-row">
        <b-col md="4" align-self="end">
          <h5>Plik rozliczeniowy</h5>
          <p class="title">
            Wgraj plik rozliczeniowy. Upewnij się, że wgrywasz aktualny plik.
          </p>
        </b-col>
        <b-col class="input-container">
          <b-form-file @change="checkXlsxParser" />
        </b-col>
      </b-row>
      <b-button
        v-if="!isLoading"
        class="mt-2 float-right"
        :disabled="!shiftsResults"
        @click="sendDataToServer"
        >Załaduj plik</b-button
      >
    </b-tab>
    <b-modal
      size="lg"
      ok-only
      centered
      ref="restfromday"
      title="Zmiany z tego dnia"
    >
      <div v-if="otherDriversToShow">
        <div
          class="other-driver"
          :class="[selectedPerson === driver.bolt_id ? 'selected' : '']"
          v-for="driver in otherDriversToShow"
          :key="driver.id"
        >
          {{ driver.full_name }} - {{ driver.total_income }} PLN
        </div>
      </div>
    </b-modal>
  </b-tabs>
</template>

<script>
import xlsxParser from "xls-parser";
export default {
  data() {
    return {
      file: null,
      shiftsResults: null,
      selectedPerson: null,
      startDate: null,
      endDate: null,
      isFetched: false,
      isLoading: false,
      fileLoading: false,
      otherDriversToShow: null,
      selected: ["km", "apps"],
      currentSelected: [
        { name: "km", label: "Kilometry", visible: true },
        { name: "apps", label: "Aplikacje + śr wyniki", visible: true },
        { name: "drivers", label: "Kierowcy", visible: false },
        { name: "compincome", label: "Rentowność", visible: false },
        { name: "bestdayres", label: "Najlepsze wyniki D", visible: false },
        { name: "worstdayres", label: "Najgorsze wyniki D", visible: false },
        { name: "bestnightres", label: "Najlepsze wyniki N", visible: false },
        { name: "worstnightres", label: "Najgorsze wyniki N", visible: false },
        { name: "bestavgres", label: "Najlepsze śr wyniki", visible: false },
        { name: "worstavgres", label: "Najgorsze śr wyniki", visible: false },
        { name: "chart", label: "Wykres", visible: false },
        { name: "appdrives", label: "Jazda na aplikacjach", visible: false },
      ],
    };
  },
  methods: {
    async checkXlsxParser(event) {
      this.fileLoading = true;
      console.log(event);

      const data = await xlsxParser.onFileSelection(event.target.files[0]);

      this.shiftsResults = data;
      // .then((data) => {
      //   this.shiftsResults = data.historia_tygodni;
      // });
      this.fileLoading = false;
    },
    async sendDataToServer() {
      this.isLoading = true;
      if (this.shiftsResults) {
        await this.$store.dispatch("fillResults", this.shiftsResults);
      }
      this.isLoading = false;
    },
    async fetchDaysPeriod() {
      await this.$store
        .dispatch("getSelectedFilters", {
          startDate: this.startDate,
          endDate: this.endDate,
        })
        .then((data) => {
          this.isFetched = true;
        });
      await this.$store.dispatch("fetchOtherResults", {
        startDate: this.startDate,
        endDate: this.endDate,
      });
    },
    changeFilter() {
      this.fetchDaysPeriod();
    },
    toggleModal(mode) {
      if (mode === "day") {
        this.otherDriversToShow = this.returnSinglePersonData.findDayBest.sort(
          (a, b) => b.total_income - a.total_income
        );
      } else {
        this.otherDriversToShow = this.returnSinglePersonData.findWorstDay.sort(
          (a, b) => b.total_income - a.total_income
        );
      }
      this.$refs["restfromday"].toggle("#toggle-modal");
    },

    getFuelFromSingleDay(item){
      // boltId z 48
      const {bolt_id, date, driven_mileage} = item;

      const findFuel = this.returnOtherResults.find(el => el.attributes.date === date && `48${el.attributes.phoneNumber}` === bolt_id)

      return findFuel ? ((findFuel.attributes.gas_amount /driven_mileage)*100).toFixed(2) : "brak danych";
      console.log(item)
    },
    getWorkingHours(item){
      // boltId z 48
      const {bolt_id, date} = item;

      const findWorkingHours = this.returnOtherResults.find(el => el.attributes.date === date && `48${el.attributes.phoneNumber}` === bolt_id)

      return findWorkingHours ? findWorkingHours.attributes.working_hours : "brak danych";
    }
  },
  computed: {
    getAllFromFiltered() {
      const data = this.$store.getters["returnResults"];
      const totalDayKm = data.totalKmD;
      const totalNightKm = data.totalKmN;
      const totalDayShifts = data.totalDayShifts;
      const totalNightShifts = data.totalNightShifts;
      const totalDaysWithMileage = data.totalDaysWithMileage;
      const totalNightsWithMileage = data.totalNightsWithMileage;
      const chartOptions = {
        chart: {
          id: "result-char",
        },
        xaxis: {
          categories: data.labels,
        },
      };
      const series = [
        {
          name: "FreeNow Dzień",
          data: data.freeNowTotalDay,
        },
        {
          name: "FreeNow Noc",
          data: data.freeNowTotalNight,
        },
        {
          name: "Uber Dzień",
          data: data.uberTotalDay,
        },
        {
          name: "Uber Noc",
          data: data.uberTotalNight,
        },
        {
          name: "Bolt Dzień",
          data: data.boltTotalDay,
        },
        {
          name: "Bolt Noc",
          data: data.boltTotalNight,
        },
        {
          name: "Ilość zmian Dzień",
          data: data.dayTotalShifts,
        },
        {
          name: "Ilość zmian Noc",
          data: data.nightTotalShifts,
        },
        {
          name: "Suma zarobku dzień",
          data: data.daysTotalIncome,
        },
        {
          name: "Suma zarobku noc",
          data: data.nightsTotalIncome,
        },
      ];
      return (
        this.isFetched && {
          options: chartOptions,
          series,
          totalDayKm,
          totalNightKm,
          totalDaysWithMileage,
          totalNightsWithMileage,
        }
      );
    },

    returnOtherResults(){
      return this.$store.getters["getOtherResults"]
    },
    returnTop5() {
      const results = this.$store.getters["returnAllResults"];
      const days = results.filter(
        (result) => result.shift === "dzień" && result.total_income > 0
      );
      const nights = results.filter(
        (result) => result.shift === "noc" && result.total_income > 0
      );
      const belowAvg = results.filter(
        (res) =>
          res.total_income <
          this.returnAvgPerKm.dayAvgResult.income /
            this.returnAvgPerKm.dayAvgResult.shifts
      );
      const upAvg = results.filter(
        (res) =>
          res.total_income >
          this.returnAvgPerKm.dayAvgResult.income /
            this.returnAvgPerKm.dayAvgResult.shifts
      );
      const bestFiveDay = days.sort(function (a, b) {
        return b.total_income - a.total_income;
      });
      const bestFiveNight = nights.sort(function (a, b) {
        return b.total_income - a.total_income;
      });
      return {
        top5: bestFiveDay.slice(0, 5),
        worst5: bestFiveDay.slice(Math.max(bestFiveDay.length - 5, 0)),
        topN5: bestFiveNight.slice(0, 5),
        worstN5: bestFiveNight.slice(Math.max(bestFiveNight.length - 5, 0)),
        top5Avg: this.returnAvgPerKm.employeeList.slice(0, 5),
        worst5Avg: this.returnAvgPerKm.employeeList.slice(
          Math.max(this.returnAvgPerKm.employeeList.length - 5, 0)
        ),
        belowAvg: belowAvg.length,
        upAvg: upAvg.length,
      };
    },
    returnCurrentUser() {
      return this.$store.getters["getCurrentUser"];
    },

    returnAvgPerKm() {
      const results = this.$store.getters["returnAllResults"];
      let sumUpDay = 0;
      let sumUpNight = 0;
      let totalKmDay = 0;
      let totalKmNight = 0;
      let totalBoltRides = 0;
      let totalUberRides = 0;
      let totalFnRides = 0;
      let dayAvgResult = { shifts: 0, income: 0, companyIncome: 0 };
      let nightAvgResult = { shifts: 0, income: 0, companyIncome: 0 };
      let employeeList = [];
      const totalIncome = results.forEach((el) => {
        let currentShift = el.shift.toLowerCase();
        let lookForEmployee = employeeList.find(
          (object) => object.id === el.bolt_id
        );
        if (lookForEmployee) {
          lookForEmployee.shifts++;
          lookForEmployee.total_income += el.total_income;
          lookForEmployee.boltRides += el.bolt_rides;
          lookForEmployee.uberRides += el.uber_rides;
          lookForEmployee.fnRides += el.fn_rides;
        } else {
          employeeList.push({
            id: el.bolt_id,
            full_name: el.full_name,
            total_income: el.total_income,
            shifts: 1,
            boltRides: el.bolt_rides,
            uberRides: el.uber_rides,
            fnRides: el.fn_rides,
          });
        }
        totalFnRides += el.fn_rides;
        totalBoltRides += el.bolt_rides;
        totalUberRides += el.uber_rides;
        currentShift === "dzień"
          ? (dayAvgResult.income += el.total_income)
          : (nightAvgResult.income += el.total_income);
        currentShift === "dzień"
          ? (dayAvgResult.companyIncome += el.income_rate)
          : (nightAvgResult.companyIncome += el.income_rate);
        currentShift === "dzień"
          ? dayAvgResult.shifts++
          : nightAvgResult.shifts++;

        el.shift.uber_rides;
        if (el.km_start > 0 && el.km_end > 0 && currentShift === "dzień") {
          sumUpDay += el.total_income;
          totalKmDay += el.driven_mileage;
        }
        if (el.km_start > 0 && el.km_end > 0 && currentShift === "noc") {
          sumUpNight += el.total_income;
          totalKmNight += el.driven_mileage;
        }
      });
      employeeList.forEach((item) => {
        item.avgPerShift = parseFloat(
          (item.total_income / item.shifts).toFixed(2)
        );
      });
      employeeList.sort((a, b) => {
        return b.avgPerShift - a.avgPerShift;
      });
      let totalRides = totalBoltRides + totalUberRides + totalFnRides;

      return {
        dayPerKm: (sumUpDay / totalKmDay).toFixed(2),
        nightPerKm: (sumUpNight / totalKmNight).toFixed(2),
        totalKmDay,
        totalKmNight,
        totalBoltRides,
        totalUberRides,
        totalFnRides,
        totalRides,
        dayAvgResult,
        nightAvgResult,
        employeeList,
      };
    },
    returnSinglePersonData() {
      const results = this.$store.getters["returnAllResults"];
      const onlySelectedPerson = results.filter(
        (el) => el.bolt_id === this.selectedPerson
      );
      const personShiftsDay = results.filter(
        (el) => el.bolt_id === this.selectedPerson && el.shift == "dzień"
      );
      const personShiftsNight = results.filter(
        (el) => el.bolt_id === this.selectedPerson && el.shift == "noc"
      );
      const sortByIncome = onlySelectedPerson.sort((a, b) => {
        return b.total_income - a.total_income;
      });
      let boltRides = 0;
      let uberRides = 0;
      let freeNowRides = 0;
      let totalRides = 0;
      let avgFuel = 0;
      let avgTotalShifts = 0;
      let avgFuelCost = 0;
      let avgFuelShifts = 0;
      let totalMilage = 0;
      let totalMileageShifts = 0;
      let avgIncome = 0;
      let avgIncomeShifts = 0;
      onlySelectedPerson.forEach((el) => {
        boltRides += el.bolt_rides;
        uberRides += el.uber_rides;
        freeNowRides += el.fn_rides;
        if (el.avg_fuel_comp > 1 && el.avg_fuel_comp < 15) {
          avgFuel += el.avg_fuel_comp;
          avgTotalShifts++;
        }
        if (el.fuel_total > 1) {
          avgFuelCost += el.fuel_total;
          avgFuelShifts++;
        }
        if (el.driven_mileage > 10 && el.driven_mileage < 1200) {
          totalMilage += el.driven_mileage;
          totalMileageShifts++;
        }
        if (el.total_income > 0) {
          avgIncome += el.total_income;
          avgIncomeShifts++;
        }
      });
      const shiftsAmount = onlySelectedPerson.length;
      totalRides = boltRides + freeNowRides + uberRides;
      const avgFuelCompsumtion = avgFuel / avgTotalShifts || 0;
      const avgTotalFuel = avgFuelCost / avgFuelShifts || 0;
      const avgPerShift = (totalMilage / totalMileageShifts).toFixed(2);
      const avgIncomeShift = (avgIncome / avgIncomeShifts).toFixed(2);
      const bestShift = sortByIncome[0];
      const worstShift = sortByIncome.at(-1);
      const findDayBest = results.filter(
        (item) => item.date === bestShift.date && item.shift === bestShift.shift
      );
      let totalFromBestDay = 0;
      let totalBestDayShifts = 0;
      findDayBest.forEach((item) => {
        if (item.total_income > 0) {
          totalFromBestDay += item.total_income;
          totalBestDayShifts++;
        }
      });
      const avgFromBestDay = parseInt(
        (totalFromBestDay / totalBestDayShifts).toFixed(2)
      );
      const findWorstDay = results.filter(
        (item) =>
          item.date === worstShift.date && item.shift === worstShift.shift
      );
      let totalFromWorstDay = 0;
      let totalWorstDayShifts = 0;
      findWorstDay.forEach((item) => {
        if (item.total_income > 0) {
          totalFromWorstDay += item.total_income;
          totalWorstDayShifts++;
        }
      });
      const avgFromWorstDay = parseInt(
        (totalFromWorstDay / totalWorstDayShifts).toFixed(2)
      );

      return {
        shiftsAmount,
        personShiftsDay,
        personShiftsNight,
        boltRides,
        uberRides,
        freeNowRides,
        totalRides,
        avgFuelCompsumtion,
        avgTotalFuel,
        totalMilage,
        avgPerShift,
        avgIncomeShift,
        sortByIncome,
        bestShift,
        worstShift,
        findDayBest,
        findWorstDay,
        avgFromBestDay,
        avgFromWorstDay,
      };
    },
    returnSelectedPeriod() {
      const results = this.$store.getters["returnAllResults"];
      const week = [];
      if (results) {
        results.forEach((el) => {
          let createdDay = week.find((day) => day.date === el.date);
          if (!createdDay) {
            week.push({ date: el.date, dayShift: [], nightShift: [] });
          }
          let findDay = week.find((day) => day.date === el.date);
          if (findDay) {
            if (el.shift.toLowerCase() === "dzień") {
              findDay.dayShift.push({ ...el });
            } else {
              findDay.nightShift.push({ ...el });
            }
          }
        });
        week.forEach((el) => {
          el.dayShift.sort((a, b) => {
            return b.income_rate - a.income_rate;
          });
          el.nightShift.sort((a, b) => {
            return b.income_rate - a.income_rate;
          });
        });
      }

      week.sort((a, b) => {
        return new Date(a.date) - new Date(b.date);
      });

      return this.isFetched && week;
    },
    returnFilterList() {
      if (this.returnCurrentUser) {
        return [
          { name: "km", label: "Kilometry", visible: true },
          { name: "apps", label: "Aplikacje + śr wyniki", visible: true },
          { name: "drivers", label: "Kierowcy", visible: false },
          { name: "compincome", label: "Rentowność", visible: false },
          { name: "bestdayres", label: "Najlepsze wyniki D", visible: false },
          { name: "worstdayres", label: "Najgorsze wyniki D", visible: false },
          { name: "bestnightres", label: "Najlepsze wyniki N", visible: false },
          {
            name: "worstnightres",
            label: "Najgorsze wyniki N",
            visible: false,
          },
          { name: "bestavgres", label: "Najlepsze śr wyniki", visible: false },
          { name: "worstavgres", label: "Najgorsze śr wyniki", visible: false },
          { name: "chart", label: "Wykres", visible: false },
          { name: "appdrives", label: "Jazda na aplikacjach", visible: false },
        ];
      } else {
        [
          { name: "km", label: "Kilometry", visible: true },
          { name: "apps", label: "Aplikacje + śr wyniki", visible: true },
          { name: "drivers", label: "Kierowcy", visible: false },
          { name: "bestdayres", label: "Najlepsze wyniki D", visible: false },
          { name: "worstdayres", label: "Najgorsze wyniki D", visible: false },
          { name: "bestnightres", label: "Najlepsze wyniki N", visible: false },
          {
            name: "worstnightres",
            label: "Najgorsze wyniki N",
            visible: false,
          },
          { name: "bestavgres", label: "Najlepsze śr wyniki", visible: false },
          { name: "worstavgres", label: "Najgorsze śr wyniki", visible: false },
          { name: "chart", label: "Wykres", visible: false },
          { name: "appdrives", label: "Jazda na aplikacjach", visible: false },
        ];
      }
    },
  },
  watch: {
    selected(newItem, oldItem) {
      if (newItem.length) {
        newItem.forEach((el) => {
          let findItem = this.currentSelected.find((item) => item.name === el);
          if (findItem) {
            findItem.visible = true;
          }
          this.currentSelected.forEach((singleItem) => {
            let checker = this.selected.find((i) => i === singleItem.name);
            if (!checker) {
              singleItem.visible = false;
            }
          });
        });
      }
    },
  },
};
</script>

<style scoped>
.vehtable {
  text-align: center;
}
.infobox {
  background-color: #3a3a3a;
  border-radius: 15px;
  color: #fff;
  padding: 20px;
}
#tab-container {
  width: 80%;
  background-color: #fff;
  border-radius: 20px;
  padding: 20px;
}
.filter {
  margin-top: auto;
}
.result-box {
  background-color: #2e59d9;
  border-radius: 16px;
  color: #fff;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 8px;
}
.total {
  font-weight: 700;
}

.prop {
  font-size: 1.2em;
  background: #fff;
  color: black;
  margin: 8px 0;
  padding: 8px 16px;
  border-radius: 8px;
}
.prop-driver {
  font-size: 1em;
  background: #fff;
  color: black;
  margin: 8px 0;
  padding: 8px 16px;
  border-radius: 8px;
}
.box-row {
  display: flex;
  justify-content: space-between;
  gap: 20px 0;
}
.percentage {
  margin-left: 8px;
  color: #00dfa2;
}
.more {
  cursor: pointer;
}
.good {
  margin-left: 4px;
  color: #00dfa2;
}
.bad {
  margin-left: 4px;
  color: #df2100;
}
.other-driver {
  border: 1px solid black;
  padding: 16px;
  border-radius: 8px;
  margin: 8px 0;
  text-align: center;
}
.selected {
  background-color: #00dfa2;
}
.single-day {
  padding: 16px 4px;
  border: 1px solid black;
  border-radius: 8px;
  margin: 8px 0;
}
.single-entry {
  padding: 8px 0;
}
</style>
