<template>
  <b-modal
    id="alt-reserve"
    size="xl"
    cancel-title="Anuluj"
    centered
    ok-title="Przypisz"
    :ok-disabled="!selectedDay"
    title="Przypisz zmianę z rezerwy"
    @ok="assingCar"
  >
    <p>Przypisywana osoba: {{ selected.fullName }}</p>
    <p>Aktualna zmiana: {{ selected.shift }}</p>
    <p>Dzień: {{ selected.stateDay }} - {{ selected.dayName }}</p>
    <h5><strong>Wolne zmiany dzień</strong></h5>
    <div class="dayrow" v-for="(item, index) in capacity.day" :key="index +'day'">
      <div class="weekday" v-if="item.drivers < item.availableCars">
        <div class="label">
          <div :class="[days[index] === selected.stateDay ? 'sameday' : '']">{{ days[index] }}</div>
          <div>{{ weekNames[index] }}</div>
        </div>
        <div class="carsrow">
          <div
            class="singlecar"
            v-for="car in item.leftCars"
            @click="setCar(car, 'day', index)"
            :key="car + 10"
                        :class="[
              selectedDay.carReg === car &&
                selectedDay.shift === 'day' &&
                index === selectedDay.weekIndex
            ? 'active' : '']"
          >
            {{ car }}
          </div>
        </div>
      </div>
    </div>
    <h5><strong>Wolne zmiany noc</strong></h5>
    <div
      class="dayrow"
      v-for="(item, index) in capacity.night"
      :key="index + 'night'"
    >
      <div class="weekday" v-if="item.drivers < item.availableCars">
        <div class="label">
          <div :class="[days[index] === selected.stateDay ? 'sameday' : '']">{{ days[index] }}</div>
          <div>{{ weekNames[index] }}</div>
        </div>
        <div class="carsrow">
          <div
            class="singlecar"
            v-for="car in item.leftCars"
            @click="setCar(car, 'night', index)"
            :key="car + 1"
            :class="[
              selectedDay.carReg === car &&
                selectedDay.shift === 'night' &&
                index === selectedDay.weekIndex
            ? 'active' : '']"
          >
            {{ car }}
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: ["selected", "drivers", "capacity", "days", "weekNames"],
  data() {
    return {
      selectedDay: { carReg: false, shift: false, weekIndex: false },
    };
  },
  methods: {
    setCar(carReg, shift, weekIndex) {
      this.selectedDay = { carReg, shift, weekIndex };
    },
    assingCar(){
      if(this.selectedDay.carReg && this.selectedDay.shift && this.selectedDay.weekIndex >= 0){
        const person = this.selected
        const select = this.selectedDay
        this.$emit('setReserve', {person, select})
        this.selectedDay = {carReg: false, shift: false, weekIndex: false,}
      }
    }
  },
};
</script>

<style scoped>
.carsrow {
  display: flex;
  flex-wrap: wrap;
  gap: 15px 10px;
}
.singlecar {
  border: 1px solid black;
  padding: 20px;
  cursor: pointer;
}
.dayrow {
  margin: 20px 0;
}
.active{
  background-color: yellow;
}
.sameday{
    width: 250px;
    text-decoration: underline;
}
</style>
