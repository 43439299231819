<template>
  <b-tabs id="tab-container" pills content-class="mt-3" align="center">
    <b-tab title="Aktywne wynajmy" active>
      <b-row class="align-items-center input-row" v-if="!isLoading && returnRentals">
        <b-col cols="6" v-for="item in returnRentals.filter(el => !el.end_date)" @click="edit(item.id)">
          <div class="active-rental">
            <div class="reg-plate">{{item.rented_car}}</div>
            <div class="name">{{item.first_name}} {{item.last_name}}</div>
            <div class="price">{{item.weekly_price}} PLN / tydzień</div>
            <div class="from">od: {{item.start_date}} {{item.start_time.slice(0, -4)}}</div>
          </div>
        </b-col>
      </b-row>


    </b-tab>
    <b-tab title="Historia">
      <b-row class="align-items-center input-row mb-2 "  v-if="!isLoading && returnRentals">
        <b-col cols="6" v-for="item in returnRentals.filter(el => el.end_date)">
          <div class="active-rental ended">
            <div class="reg-plate">{{item.rented_car}}</div>
            <div class="name">{{item.first_name}} {{item.last_name}}</div>
            <div class="price">{{item.weekly_price}} PLN / tydzień</div>
            <div class="from">od: {{item.start_date}} {{item.start_time.slice(0, -4)}}</div>
          </div>
        </b-col>
      </b-row>

    </b-tab>
    <b-tab title="Dodaj nowy wynajem">

      <b-row class="align-items-center input-row mb-2">
        <b-col cols="6">
          <b-form-group label="Imię">
            <b-form-input v-model="form.first_name"/>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group label="Nazwisko">
            <b-form-input v-model="form.last_name" />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group label="Wybierz samochód">
            <b-form-select v-model="form.rented_car">
              <b-form-select-option :value="car.reg" v-for="car in returnCars">{{car.reg}}</b-form-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group label="Opłata tygodniowa">
            <b-form-input type="number" v-model="form.weekly_price" />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group label="Przebieg start">
            <b-form-input type="number" v-model="form.mileage_start" />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group label="Przebieg koniec">
            <b-form-input type="number" disabled v-model="form.mileage_end" />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group label="Start wynajmu">
            <b-form-datepicker  start-weekday="1" v-model="form.start_date"/>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group label="Koniec wynajmu">
            <b-form-datepicker  start-weekday="1" disabled v-model="form.end_date"/>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group label="Godzina rozpoczęcia" v-model="form.start_time">
            <b-form-timepicker v-model="form.start_time" locale="pl" placeholder="Wybierz godzinę" />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group label="Godzina zakończenia" >
            <b-form-timepicker v-model="form.end_time" locale="pl" placeholder="Wybierz godzinę" disabled />
          </b-form-group>
        </b-col>
      </b-row>
      
    </b-row>
      <b-row class="float-right">
                <b-button class="mr-2" variant="primary" @click="sendForm">Dodaj wynajem</b-button>

      </b-row>

    </b-tab>

  </b-tabs>
</template>

<script>
import NewCar from "../components/vehicles/NewCar.vue";
import EditCar from '../components/vehicles/EditCar.vue';
export default {
  components: { NewCar, EditCar },
  data() {
    return {
      isLoading: false,
      sortBy: 'reg',
      form: {
        first_name: null,
        last_name: null,
        rented_car: null,
        weekly_price: null,
        mileage_start: null,
        mileage_end: null,
        start_date: null,
        start_time: null,
        end_date: null,
        end_time: null
      },

    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.isLoading = true;
      await this.$store.dispatch("getCars");
      await this.$store.dispatch("getRentals");
      this.isLoading = false;
    },
    async edit(val){
      this.$router.push({path: `/admin/wylacznosc/${val}`})
      console.log(val)
    },
    async sendForm(){
          
          this.$store.dispatch("addRental", this.form).then((res) => {
          this.$bvModal.msgBoxOk("Wynajem dodany został pomyślnie.", {
            headerBgVariant: "primary",
            title: "Dodano pomyślnie",
            headerTextVariant: "light",
            centered: true,
          });
        });
    }
  },
  computed: {
    returnCars() {
      const cars = this.$store.getters["returnCars"]
      return !this.isLoading && cars.filter(el => el.isRental);
    },
    returnRentals() {
      const rentals = this.$store.getters["getRentals"]
      return !this.isLoading && rentals;
    },
  },
};
</script>

<style scoped>
.vehtable {
  text-align: center;
}
.infobox {
  background-color: #3a3a3a;
  border-radius: 15px;
  color: #fff;
  padding: 20px;
}
#tab-container {
  width: 80%;
  background-color: #fff;
  border-radius: 20px;
  padding: 20px;
}
.filter {
  margin-top: auto;
}
.result-box {
  background-color: #2e59d9;
  border-radius: 16px;
  color: #fff;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 8px;
}
.total {
  font-weight: 700;
}

.prop {
  font-size: 1.2em;
  background: #fff;
  color: black;
  margin: 8px 0;
  padding: 8px 16px;
  border-radius: 8px;
}
.prop-driver {
  font-size: 1em;
  background: #fff;
  color: black;
  margin: 8px 0;
  padding: 8px 16px;
  border-radius: 8px;
}
.box-row {
  display: flex;
  justify-content: space-between;
  gap: 20px 0;
}
.percentage {
  margin-left: 8px;
  color: #00dfa2;
}
.more {
  cursor: pointer;
}
.good {
  margin-left: 4px;
  color: #00dfa2;
}
.bad {
  margin-left: 4px;
  color: #df2100;
}
.other-driver {
  border: 1px solid black;
  padding: 16px;
  border-radius: 8px;
  margin: 8px 0;
  text-align: center;
}
.selected {
  background-color: #00dfa2;
}
.single-day {
  padding: 16px 4px;
  border: 1px solid black;
  border-radius: 8px;
  margin: 8px 0;
}
.single-entry {
  padding: 8px 0;
}

.active-rental{
  border: 2px solid rgb(105, 241, 105);
  border-radius: 24px;
  padding: 40px;
  cursor: pointer;
}
.active-rental .reg-plate{
  font-weight: 600;
  font-size: 1.4em;
}

.active-rental .from {
  font-weight: 300;
}

.ended{
  border: 1px solid rgb(155, 46, 46);
}
</style>
