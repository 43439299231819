var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-modal',{attrs:{"id":"alt-reserve","size":"xl","cancel-title":"Anuluj","centered":"","ok-title":"Przypisz","ok-disabled":!_vm.selectedDay,"title":"Przypisz zmianę z rezerwy"},on:{"ok":_vm.assingCar}},[_c('p',[_vm._v("Przypisywana osoba: "+_vm._s(_vm.selected.fullName))]),_c('p',[_vm._v("Aktualna zmiana: "+_vm._s(_vm.selected.shift))]),_c('p',[_vm._v("Dzień: "+_vm._s(_vm.selected.stateDay)+" - "+_vm._s(_vm.selected.dayName))]),_c('h5',[_c('strong',[_vm._v("Wolne zmiany dzień")])]),_vm._l((_vm.capacity.day),function(item,index){return _c('div',{key:index +'day',staticClass:"dayrow"},[(item.drivers < item.availableCars)?_c('div',{staticClass:"weekday"},[_c('div',{staticClass:"label"},[_c('div',{class:[_vm.days[index] === _vm.selected.stateDay ? 'sameday' : '']},[_vm._v(_vm._s(_vm.days[index]))]),_c('div',[_vm._v(_vm._s(_vm.weekNames[index]))])]),_c('div',{staticClass:"carsrow"},_vm._l((item.leftCars),function(car){return _c('div',{key:car + 10,staticClass:"singlecar",class:[
            _vm.selectedDay.carReg === car &&
              _vm.selectedDay.shift === 'day' &&
              index === _vm.selectedDay.weekIndex
          ? 'active' : ''],on:{"click":function($event){return _vm.setCar(car, 'day', index)}}},[_vm._v(" "+_vm._s(car)+" ")])}),0)]):_vm._e()])}),_c('h5',[_c('strong',[_vm._v("Wolne zmiany noc")])]),_vm._l((_vm.capacity.night),function(item,index){return _c('div',{key:index + 'night',staticClass:"dayrow"},[(item.drivers < item.availableCars)?_c('div',{staticClass:"weekday"},[_c('div',{staticClass:"label"},[_c('div',{class:[_vm.days[index] === _vm.selected.stateDay ? 'sameday' : '']},[_vm._v(_vm._s(_vm.days[index]))]),_c('div',[_vm._v(_vm._s(_vm.weekNames[index]))])]),_c('div',{staticClass:"carsrow"},_vm._l((item.leftCars),function(car){return _c('div',{key:car + 1,staticClass:"singlecar",class:[
            _vm.selectedDay.carReg === car &&
              _vm.selectedDay.shift === 'night' &&
              index === _vm.selectedDay.weekIndex
          ? 'active' : ''],on:{"click":function($event){return _vm.setCar(car, 'night', index)}}},[_vm._v(" "+_vm._s(car)+" ")])}),0)]):_vm._e()])})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }