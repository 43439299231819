import Vue from "vue";

export default {
  async getAvailability(context, payload) {
    const response = await Vue.axios
      .get(
        `/schedules?month=${payload.month}&year=${payload.year}&week=${payload.week}`
      )
      .catch((error) => console.log("fetch failed"));
    context.commit("setAvailability", response.data);
  },
  async userFetch(context, payload) {
    let jwt = context.state.user.jwt;
    const response = await Vue.axios.get("/api/schedules/details", {
      params: { week: payload.week, year: payload.year, jwt: jwt },
    });
    context.commit("setUserFetch", response.data);
  },

  async sendForm(context, payload) {
    let currentMonth = null;
    let currentYear = null;
    let parsedDays = [];
    if (!payload.empty) {
      for (let i in payload.selectedDays) {
        let shiftOption = payload.selectedDays[i].shiftOption;
        let day = payload.selectedDays[i].day;
        let month = payload.selectedDays[i].month;
        let year = payload.selectedDays[i].year;
        let isWeekend = payload.selectedDays[i].isWeekend;
        currentMonth = payload.selectedDays[i].month;
        currentYear = payload.selectedDays[i].year;
        parsedDays.push({
          shiftOption: shiftOption,
          day: day,
          month: month,
          year: year,
          isWeekend: isWeekend,
        });
      }
      const response = await Vue.axios.post("/api/add", {
        fullName: payload.fullName,
        phone: payload.phone,
        email: payload.email,
        month: currentMonth,
        year: currentYear,
        selectedDays: parsedDays,
        originalDays: parsedDays,
        weekNum: payload.weekNum,
        empty: false,
      });
    } else {
      const response = await Vue.axios.post("/api/add", {
        fullName: payload.fullName,
        phone: payload.phone,
        email: payload.email,
        month: payload.month,
        year: payload.year,
        selectedDays: [],
        originalDays: [],
        weekNum: payload.weekNum,
        empty: true,
      });
    }
  },
  async addSingleShift(context, payload) {
    let jwt = context.state.user.jwt;
    let options = { headers: { Authorization: `Bearer ${jwt}` } };
    const response = await Vue.axios
      .put("/api/update-shifts", { data: payload, options: options })
      .then((res) => console.log("added!"))
      .catch((error) => console.log(error));
    context.commit("setSingleShift", payload);
  },
  async removeSingleShift(context, payload) {
    let jwt = context.state.user.jwt;
    let options = { headers: { Authorization: `Bearer ${jwt}` } };
    const id = parseInt(payload.index);
    let index = context.state.userFetch.schedules.findIndex((x) => x.id === id);
    context.commit("removeShift", payload);
    let newShifts =
      context.state.userFetch.schedules[index].attributes.selectedDays;
    const response = await Vue.axios.put("/api/update-shifts", {
      data: { newDate: newShifts, id: payload.index },
      options: options,
    });
  },
  async changeWeek(context, payload) {
    let jwt = context.state.user.jwt;
    let options = { headers: { Authorization: `Bearer ${jwt}` } };
    const response = await Vue.axios.put("/api/week", {
      data: payload,
      options: options,
    });
  },
  async changeYear(context, payload) {
    let jwt = context.state.user.jwt;
    let options = { headers: { Authorization: `Bearer ${jwt}` } };
    const response = await Vue.axios.put("/api/year", {
      year: payload,
      options: options,
    });
  },
  async fetchCalendar(context, payload) {
    let jwt = context.state.user.jwt;
    const response = await Vue.axios.get("/api/calendar", {
      params: { month: payload.month, year: payload.year, jwt: jwt },
    });
    context.commit("setCalendar", response.data);
  },
  async adminYearMonth(context, payload) {
    let jwt = context.state.user.jwt;
    let options = { headers: { Authorization: `Bearer ${jwt}` } };
    if (payload.mode === "week") {
      const response = await Vue.axios.put("/api/month-year", {
        mode: payload.mode,
        data: {
          adminWeek: payload.value,
        },
        options: options,
      });
    } else {
      const response = await Vue.axios.put("/api/month-year", {
        mode: payload.mode,
        year: payload.value,
        options: options,
      });
    }
    context.commit("changeAdminMonthYear", payload);
  },
  async changeShowWeek(context, payload) {
    let jwt = context.state.user.jwt;
    let options = { headers: { Authorization: `Bearer ${jwt}` } };
    const response = await Vue.axios.put("/api/show-week", {
      data: payload,
      options: options,
    });
  },
  async changeDefaultLimit(context, payload) {
    let jwt = context.state.user.jwt;
    let options = { headers: { Authorization: `Bearer ${jwt}` } };
    const response = await Vue.axios.put("/api/defaultLimit", {
      data: { defaultLimit: payload },
      options: options,
    });
  },
  async fetchSettings(context) {
    const response = await Vue.axios.get("/api/settings");
    context.commit("setSettings", response.data);
  },
  async fetchASettings(context) {
    let jwt = context.state.user.jwt;
    const response = await Vue.axios.get("/api/asettings", {
      params: { jwt: jwt },
    });
    context.commit("setSettings", response.data);
  },
  async fetchDashboardDailyResult(context) {
    let jwt = context.state.user.jwt;
    const response = await Vue.axios.get("/api/get-dashboard-results", {
      params: { jwt: jwt },
    });
    context.commit("setDashboardResults", response.data);
  },
  async fetchDashboardWeeklyResult(context) {
    let jwt = context.state.user.jwt;
    const response = await Vue.axios.get("/api/get-dashboard-results-weekly", {
      params: { jwt: jwt },
    });
    context.commit("setDashboardWeeklyResults", response.data);
  },
  async fetchDetails(context, payload) {
    let jwt = context.state.user.jwt;
    const response = await Vue.axios.get("/api/schedules/details", {
      params: { week: payload.week, year: payload.year, jwt: jwt },
    });
    context.commit("setDetails", response.data);
  },
  async fetchPrev(context, payload) {
    let jwt = context.state.user.jwt;
    const response = await Vue.axios.get("/api/schedules/details", {
      params: { month: payload.month, year: payload.year, jwt: jwt },
    });
    context.commit("setPreviousMonth", response.data);
  },
  async addLimits(context, payload) {
    let jwt = context.state.user.jwt;
    let options = { headers: { Authorization: `Bearer ${jwt}` } };
    let checkAmount = context.state.settingsDetails.activeLimits.filter(
      (item) =>
        item.dayNumber === payload.dayNumber &&
        item.month === payload.month &&
        item.year === payload.year
    );
    if (checkAmount.length >= 0) {
      let isAnotherDay = false;
      let isAnotherNight = false;
      for (let item in checkAmount) {
        checkAmount[item].day && payload.day ? (isAnotherDay = true) : "";
        checkAmount[item].night && payload.night ? (isAnotherNight = true) : "";
      }
      if (payload.day && payload.night && isAnotherDay && isAnotherNight) {
        return Promise.reject(
          "Jest już dodany limit na cały dzień dla wybranego dnia."
        );
      }
      if (payload.night && isAnotherNight) {
        return Promise.reject(
          "Jest już dodany limit na zmiane Nocną dla wybranego dnia."
        );
      }
      if (payload.day && isAnotherDay) {
        return Promise.reject(
          "Jest już dodany limit na zmiane dzienną dla wybranego dnia."
        );
      }
      if (
        (payload.day && !isAnotherDay) ||
        (payload.night && !isAnotherNight)
      ) {
        console.log("wysyłam");
        const response = await Vue.axios.post("/api/add-day-limit", {
          data: payload,
          options: options,
        });
        if (response.data.status === 200) {
          context.commit("setActiveLimits", response.data.item);
        }
      }
    }
  },
  async removeLimit(context, payload) {
    let options = {
      headers: { Authorization: `Bearer ${context.state.user.jwt}` },
    };
    const response = await Vue.axios
      .delete("/api/limits/remove", {
        data: { id: payload.id, options: options },
      })
      .then((res) => context.commit("removeLimit", payload.id));
  },
  changeCalendarMode(context, payload) {
    context.commit("setCalendarMode", payload);
  },
  async getExcelReady(context, payload) {
    let jwt = context.state.user.jwt;
    const response = await Vue.axios.get("/adminscheduler", {
      params: { adminWeek: payload.adminWeek, year: payload.year, jwt: jwt },
    });
    if (payload.fetch) {
      const details = await Vue.axios.get("/api/schedules/details", {
        params: { adminWeek: payload.adminWeek, year: payload.year, jwt: jwt },
      });
      context.commit("setDetails", details.data);
      context.commit("changeCalPosition", payload);
    }
    context.commit("setCalendar", response.data);
  },
  async signSchedule(context, payload) {
    let jwt = context.state.user.jwt;
    let options = { headers: { Authorization: `Bearer ${jwt}` } };
    const response = await Vue.axios.put("/api/signSchedule", {
      id: payload.id,
      isSigned: payload.isSigned,
      options: options,
    });
    if (response.status === 200) {
      context.commit("signSchedule", {
        id: payload.id,
        signed: payload.isSigned,
      });
    } else {
      console.log("błąd połączenia!");
    }
  },
  async toggleCard(context, payload) {
    let jwt = context.state.user.jwt;
    let options = { headers: { Authorization: `Bearer ${jwt}` } };
    const response = await Vue.axios.put("/api/toggleCard", {
      id: payload.id,
      fuelCard: payload.fuelCard,
      options: options,
    });
    if (response.status === 200) {
      context.commit("toggleCard", {
        id: payload.id,
        fuelCard: payload.fuelCard,
      });
    } else {
      console.log("błąd połączenia!");
    }
  },
  changeSelection(context, payload) {
    let jwt = context.state.user.jwt;
    let options = { headers: { Authorization: `Bearer ${jwt}` } };
    context.commit("setSelected", payload);
  },
  // auth module
  async login(context, payload) {
    const jwt = localStorage.getItem("identifier");

    const response = await Vue.axios
      .post(`/auth`, payload)
      .catch((error) => console.log("login failed"));
    if (!jwt) {
      localStorage.setItem("identifier", response.data.jwt);
    }
    context.commit("loginUser", response.data);
  },
  async autoLogin(context, payload) {
    const jwt = localStorage.getItem("identifier");
    let isConfirmed = false;

    if (jwt) {
      const response = await Vue.axios
        .get("/auth/me", { params: { jwt: jwt } })
        .then((res) => {
          if(res.status === 200){
            context.commit("loginUser", res.data);
            isConfirmed = true;
          } else {
            console.log("not confirmed")
            isConfirmed = false;
          }
        })
        .catch((err) => console.log(err));
        return isConfirmed
    }
  },
  async logAsEditor(context, payload) {
    const jwt = context.state.user.jwt;
    const userId = context.state.user.id;
    let options = { headers: { Authorization: `Bearer ${jwt}` } };

    const response = await Vue.axios.put("/api/setEditor", {
      data: userId,
      options,
      mode: payload,
    });
  },
  logOut(context) {
    context.commit("logOut");
  },
  // new modules
  async addCar(context, payload) {
    let jwt = context.state.user.jwt;
    let options = { headers: { Authorization: `Bearer ${jwt}` } };
    const response = await Vue.axios
      .post("/api/add-car", { data: payload, options: options })
      .then((res) => {
        context.commit("addCar", res);
      })
      .catch((err) => console.log("Czy to błąd?"));
  },
  async addRental(context, payload) {
    let jwt = context.state.user.jwt;
    let options = { headers: { Authorization: `Bearer ${jwt}` } };
    const response = await Vue.axios
      .post("/api/add-rental", { data: payload, options: options })
      .then((res) => {
        // context.commit("addCar", res);
      })
      .catch((err) => console.log("Czy to błąd?"));
  },
  async updateCar(context, payload) {
    let jwt = context.state.user.jwt;
    let options = { headers: { Authorization: `Bearer ${jwt}` } };
    const response = await Vue.axios
      .put("/api/edit-car", { data: payload, options: options })
      .then((res) => {
        context.commit("updateCar", res);
      })
      .catch((err) => {
        throw err;
      });
  },
  async getCars(context) {
    let jwt = context.state.user.jwt;
    const response = await Vue.axios
      .get("/api/get-cars", { params: { jwt: jwt } })
      .then((res) => {
        context.commit("setCars", res.data);
      });
  },
  async getRentals(context) {
    let jwt = context.state.user.jwt;
    const response = await Vue.axios
      .get("/api/get-rentals", { params: { jwt: jwt } })
      .then((res) => {
        context.commit("setRentals", res.data);
      });
  },
  async fetchCarDrivers(context, payload) {
    let jwt = context.state.user.jwt;
    const response = await Vue.axios
      .get("/api/get-car-drivers", { params: { jwt: jwt, id: payload } })
      .then((res) => {
        context.commit("setDrivers", res.data);
      });
  },
  async addDriver(context, payload) {
    let jwt = context.state.user.jwt;
    let options = { headers: { Authorization: `Bearer ${jwt}` } };
    const response = await Vue.axios
      .post("/api/add-driver", { data: payload, options: options })
      .then((res) => {
        context.commit("addDriver", res);
      })
      .catch((err) => console.log("Czy to błąd?"));
  },
  async addDriverToList(context, payload) {
    let jwt = context.state.user.jwt;
    let options = { headers: { Authorization: `Bearer ${jwt}` } };
    const response = await Vue.axios
      .post("/api/add-driver-to-list", { data: payload, options: options })
      .then((res) => {
        context.commit("addDriverToDriversList", res);
      })
      .catch((err) => console.log("Czy to błąd?"));
  },
  async generateWeek(context, payload) {
    let jwt = context.state.user.jwt;
    let options = { headers: { Authorization: `Bearer ${jwt}` } };
    const response = await Vue.axios
      .post("/api/generate-week", { data: payload, options: options })
      .then((res) => {
        context.commit("generateWeek", res.data);
      })
      .catch((err) => console.log("Czy to błąd?"));
  },
  async updateWeek(context, payload) {
    const jwt = context.state.user.jwt;
    const user = context.state.user.user;
    const id = context.state.generatedWeeks[0].id;
    const week = context.state.generatedWeeks[0].week;
    const history = payload.history;
    const options = { headers: { Authorization: `Bearer ${jwt}` } };
    const response = await Vue.axios
      .put("/api/update-week", {
        data: payload,
        history: history,
        options: options,
        id: id,
        user,
        week,
      })
      .then((res) => {
        // context.commit("updateWeek", res.data);
      })
      .catch((err) => console.log("Czy to błąd?"));
  },
  async fetchGeneratedWeek(context, payload) {
    let jwt = context.state.user.jwt;
    const response = await Vue.axios
      .get("/api/get-generated-week", {
        params: { jwt: jwt, week: payload.week, year: payload.year },
      })
      .then((res) => {
        context.commit("generateWeek", res.data);
      })
      .catch((err) => console.log("Czy to błąd?"));
  },
  async toogleScheduleView(context, payload) {
    const jwt = context.state.user.jwt;
    const options = { headers: { Authorization: `Bearer ${jwt}` } };
    const response = await Vue.axios
      .put("/api/update-view", { data: payload, options: options })
      .then((res) => {
        // context.commit("updateWeek", res.data);
      })
      .catch((err) => console.log("Czy to błąd?"));
  },
  async changePin(context, payload) {
    const jwt = context.state.user.jwt;
    const options = { headers: { Authorization: `Bearer ${jwt}` } };
    const response = await Vue.axios
      .put("/api/change-pin", { data: payload, options: options })
      .then((res) => {
        context.commit("setPin", payload);
      })
      .catch((err) => console.log("Czy to błąd?"));
  },
  async checkPin(context, payload) {
    const response = await Vue.axios
      .get("/api/check-pin", { params: { pin: payload } })
      .then((res) => {
        context.commit("pinStatus", res);
      })
      .catch((err) => console.log("Czy to błąd?"));
  },
  async checkPinAndPhone(context, payload) {
    const pin = payload.pin;
    const phone = payload.phone;
    const response = await Vue.axios
      .get("/api/check-pin-and-phone", { params: { pin, phone } })
      .then((res) => {
        context.commit("pinStatus", res);
      })
      .catch((err) => context.commit("pinStatus", res));
  },
  async fetchPublicCalendar(context, payload) {
    const response = await Vue.axios
      .get("/api/get-public-week")
      .then((res) => {
        context.commit("setPublicCalendar", res.data);
      })
      .catch((err) => console.log("czy to błąd?"));
  },
  async fetchCurrentWeek(context, payload) {
    const response = await Vue.axios
      .get("/api/get-current-calendar", {
        params: { week: payload.week, year: payload.year },
      })
      .then((res) => {
        context.commit("setCurrentCalendar", res.data);
      })
      .catch((err) => console.log("czy to błąd?"));
  },
  async fetchPreviusWeek(context, payload) {
    const response = await Vue.axios
      .get("/api/get-current-calendar", {
        params: { week: payload.week, year: payload.year },
      })
      .then((res) => {
        context.commit("setPreviusWeek", res.data);
      })
      .catch((err) => console.log("czy to błąd?"));
  },
  // single driver
  async fetchPublicSingleCalendar(context, payload) {
    const phone = payload.phone;
    const response = await Vue.axios
      .get("/api/get-public-solo-week", { params: { solo: true, phone } })
      .then((res) => {
        context.commit("setPublicCalendar", res.data);
      })
      .catch((err) => console.log("czy to błąd?"));
  },
  async fetchCurrentSingleWeek(context, payload) {
    const phone = payload.phone;
    const response = await Vue.axios
      .get("/api/get-current-solo-calendar", {
        params: { week: payload.week, year: payload.year, solo: true, phone },
      })
      .then((res) => {
        context.commit("setCurrentCalendar", res.data);
      })
      .catch((err) => console.log("czy to błąd?"));
  },
  async fetchPreviusSingleWeek(context, payload) {
    const phone = payload.phone;
    const response = await Vue.axios
      .get("/api/get-current-solo-calendar", {
        params: { week: payload.week, year: payload.year, solo: true, phone },
      })
      .then((res) => {
        context.commit("setPreviusWeek", res.data);
      })
      .catch((err) => console.log("czy to błąd?"));
  },

  // end of single driver
  async removeDriver(context, payload) {
    let jwt = context.state.user.jwt;
    const response = await Vue.axios
      .delete("/api/remove-driver", { params: { id: payload, jwt: jwt } })
      .then((res) => {
        context.commit("removeDriver", payload);
      })
      .catch((err) => console.log("error"));
  },
  async addAdditional(context, payload) {
    const response = await Vue.axios
      .post("/api/addeddays", { item: payload })
      .then((res) => {})
      .catch((err) => {});
  },
  async getDrivers(context) {
    let jwt = context.state.user.jwt;
    const response = await Vue.axios("/api/getalldrivers", {
      params: { jwt: jwt },
    })
      .then((res) => {
        context.commit("setAllDrivers", res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  async toggleDriver(context, payload) {
    const jwt = context.state.user.jwt;
    const options = { headers: { Authorization: `Bearer ${jwt}` } };
    const response = await Vue.axios
      .put("/api/toggledriver", { data: payload, options: options })
      .then((res) => {
        context.commit("toggleDriver", payload);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  async removeDriverList(context, payload) {
    let jwt = context.state.user.jwt;
    const response = await Vue.axios
      .delete("/api/remove-driver-list", { params: { id: payload, jwt: jwt } })
      .then((res) => {
        context.commit("removeDriverList", payload);
      })
      .catch((err) => console.log("error"));
  },
  async sendSMS(context, payload) {
    let jwt = context.state.user.jwt;
    if (jwt) {
      const response = await Vue.axios
        .post("/api/text", { item: payload })
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  },
  async sendAllSMS(context, payload) {
    let jwt = context.state.user.jwt;
    if (jwt) {
      const response = await Vue.axios
        .post("/api/text-all", { changes: payload })
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  },
  async removeSchedule(context, payload) {
    let jwt = context.state.user.jwt;
    const response = await Vue.axios
      .delete("/api/remove-schedule", { params: { id: payload, jwt: jwt } })
      .then((res) => {
        context.commit("removeSchedule", payload);
      })
      .catch((err) => console.log(err));
  },
  async getGrounded(context, payload) {
    let jwt = context.state.user.jwt;
    let options = { headers: { Authorization: `Bearer ${jwt}` } };
    const response = await Vue.axios("/api/get-grounded", {
      params: { jwt: jwt, id: payload },
    }).then((res) => {
      context.commit("setSingleGrounded", res.data.data);
    });
  },
  async getAllGrounded(context, payload) {
    let jwt = context.state.user.jwt;
    let options = { headers: { Authorization: `Bearer ${jwt}` } };
    const response = await Vue.axios("/api/get-all-grounded", {
      params: { jwt: jwt },
    }).then((res) => {
      context.commit("setAllGrounded", res.data);
    });
  },
  async addGrounded(context, payload) {
    let jwt = context.state.user.jwt;
    let options = { headers: { Authorization: `Bearer ${jwt}` } };
    const response = await Vue.axios
      .post("/api/add-grounded", { data: payload, options: options })
      .then((res) => {
        context.commit("addStateGrounded", res.data);
      })
      .catch((err) => console.log(err));
  },
  async editGrounded(context, payload) {
    let jwt = context.state.user.jwt;
    let options = { headers: { Authorization: `Bearer ${jwt}` } };
    const response = await Vue.axios
      .put("/api/edit-grounded", { data: payload, options: options })
      .then((res) => {
        // context.commit('editStateGrounded', payload)
      })
      .catch((err) => console.log(err));
  },
  async removeGrounded(context, payload) {
    let jwt = context.state.user.jwt;
    const response = await Vue.axios
      .delete("/api/remove-grounded", { params: { id: payload, jwt: jwt } })
      .then((res) => {
        context.commit("removeGrounded", payload);
      })
      .catch((err) => console.log(err));
  },
  async fetchGeneratedWeeks(context, payload) {
    let jwt = context.state.user.jwt;
    const year = payload.year;
    let options = { headers: { Authorization: `Bearer ${jwt}` } };
    const response = await Vue.axios("/api/get-generated-weeks", {
      params: { jwt: jwt, year: year },
    }).then((res) => {
      context.commit("setAllGenerated", res.data);
    });
  },
  async addService(context, payload) {
    let jwt = context.state.user.jwt;
    let options = { headers: { Authorization: `Bearer ${jwt}` } };
    const response = await Vue.axios
      .post("/api/add-service", { data: payload, options: options })
      .then((res) => {
        context.commit("addSingleService", res.data);
      })
      .catch((err) => console.log(err));
  },
  async updateService(context, payload) {
    let jwt = context.state.user.jwt;
    let options = { headers: { Authorization: `Bearer ${jwt}` } };
    const response = await Vue.axios
      .put("/api/update-service", { data: payload, options: options })
      .then((res) => {
        // context.commit("updateSingleService", res.data);
      })
      .catch((err) => console.log(err));
  },
  async getSingleServices(context, payload) {
    let jwt = context.state.user.jwt;
    let options = { headers: { Authorization: `Bearer ${jwt}` } };
    const response = await Vue.axios("/api/get-single-service", {
      params: { jwt: jwt, id: payload },
    }).then((res) => {
      context.commit("addService", res.data.data);
    });
  },
  async getWeekServices(context, payload) {
    let jwt = context.state.user.jwt;
    const response = await Vue.axios("/api/get-week-service", {
      params: { week: payload, jwt: jwt },
    })
      .then((res) => {
        if (res.status === 200 && res.data.data) {
          context.commit("setWeekServices", res.data.data);
        }
      })
      .catch((err) => console.log(err));
  },
  async fetchPublicServices(context, payload) {
    const response = await Vue.axios("/api/get-public-service", {
      params: { week: payload },
    })
      .then((res) => {
        if (res.status === 200 && res.data.data) {
          context.commit("setPublicServices", res.data.data);
        }
      })
      .catch((err) => console.log(err));
  },
  async removeService(context, payload) {
    let jwt = context.state.user.jwt;
    const response = await Vue.axios
      .delete("/api/remove-service", { params: { id: payload, jwt: jwt } })
      .then((res) => {
        if (res.status === 200) {
          context.commit("removeService", payload);
        }
      })
      .catch((err) => console.log(err));
  },
  async assignedDrivers(context) {
    let jwt = context.state.user.jwt;
    const response = await Vue.axios("/api/get-assigned-drivers", {
      params: { jwt: jwt },
    }).then((res) => {
      context.commit("setAssignedDrivers", res.data);
    });
  },
  async getAvgSingleWeek(context, payload) {
    let jwt = context.state.user.jwt;
    let options = { headers: { Authorization: `Bearer ${jwt}` } };
    const response = await Vue.axios
      .post("/api/get-single-avg", { data: payload, options })
      .then((res) => {})
      .catch((err) => console.log(err));
  },
  async getAvgPeriod(context, payload) {
    let jwt = context.state.user.jwt;
    let options = { headers: { Authorization: `Bearer ${jwt}` } };
    const response = await Vue.axios
      .post("/api/get-period-avg", { data: payload, options })
      .then((res) => {})
      .catch((err) => console.log(err));
  },
  async deleteCar(context, payload) {
    let jwt = context.state.user.jwt;
    const response = await Vue.axios
      .delete("/api/delete-car", { params: { id: payload, jwt: jwt } })
      .then((res) => {
        context.commit("removeCar", payload);
      })
      .catch((err) => console.log("error"));
  },
  async getUsersInEdit(context, payload) {
    let jwt = context.state.user.jwt;
    const response = await Vue.axios("/api/get-users-in-edit", {
      params: { jwt: jwt },
    }).then((res) => {
      context.commit("setUsersInEdit", res.data);
    });
  },
  async fillResults(context, payload) {
    let jwt = context.state.user.jwt;
    let options = { headers: { Authorization: `Bearer ${jwt}` } };
    const response = await Vue.axios.post("/api/fill-results", {
      data: payload,
      options,
    });
  },
  async getSelectedFilters(context, payload) {
    const jwt = context.state.user.jwt;
    const startDate = payload.startDate;
    const endDate = payload.endDate;
    const response = await Vue.axios("/api/get-period-filters", {
      params: { startDate, endDate, jwt },
    }).then((data) => {
      context.commit("setResults", data.data);
    });
    console.log(startDate);
  },
  async getDailyResults(context, payload) {
    const jwt = context.state.user.jwt;
    const startDate = payload.startDate;
    const endDate = payload.endDate;
    const response = await Vue.axios("/api/get-daily-results", {
      params: { startDate, endDate, jwt },
    }).then((data) => {
      context.commit("setDailyResults", data.data);
    });
  },
  async getWeeklyResults(context, payload) {
    const jwt = context.state.user.jwt;
    const startDate = payload.startDate;
    const endDate = payload.endDate;
    const response = await Vue.axios("/api/get-weekly-results", {
      params: { startDate, endDate, jwt },
    }).then((data) => {
      context.commit("setWeeklyResults", data.data);
    });
  },
  async fetchOtherResults(context, payload) {
    const jwt = context.state.user.jwt;
    const startDate = payload.startDate;
    const endDate = payload.endDate;
    const response = await Vue.axios("/api/get-period-fuel-working-time", {
      params: { startDate, endDate, jwt },
    }).then((data) => {
      context.commit("setOtherResults", data.data);
    });
    console.log(startDate);
  },
  async addBonus(context, payload) {
    const jwt = context.state.user.jwt;
    let options = { headers: { Authorization: `Bearer ${jwt}` } };
    const response = await Vue.axios
      .post("/api/add-bonus", { data: payload, options })
      .then((res) => {
        context.commit("addSingleBonus", payload);
      });
  },
  async getBonuses(context) {
    const jwt = context.state.user.jwt;
    const response = await Vue.axios("/api/get-bonuses", {
      params: { jwt },
    }).then((res) => {
      context.commit("setBonuses", res.data);
    });
  },
  async getPublicBonus(context, week) {
    const response = await Vue.axios("/api/get-public-bonuses", {
      params: { week },
    }).then((res) => {
      context.commit("setBonuses", res.data);
    });
  },
  async checkFuelPIN(context, payload) {
    const response = await Vue.axios
      .post("/api/check-fuel", { data: payload })
      .then((res) => {
        const { name } = res.data;
        if (res.status === 200) {
          context.commit("addFuelAuth", { ...payload, name });
        }
      })
      .catch((err) => {
        throw new Error(err);
      });
  },
  async fetchFuelWeek(context, payload) {
    const week = payload.week;
    const year = payload.year;
    const pass = payload.pass;
    const response = await Vue.axios("/api/get-fuel-week", {
      params: { week, year, pass },
    }).then((data) => {
      context.commit("setFuelWeek", data.data);
    });
    // console.log(startDate)
  },

  async fetchAlreadySaved(context, payload){
    const response = await Vue.axios.post("/api/get-saved-fuel", {
      data: payload
    }).then((data) => {
      context.commit("setSavedFuel", data.data);
    });
  },

  async addDayFuel(context, payload) {
    const response = await Vue.axios
      .post("/api/add-day-fuel", { data: payload })
      .then((res) => {})
      .catch((err) => {
        throw new Error(err);
      });
  },
};
