 <template>
  <b-navbar toggleable type="dark" variant="dark" class="navbar">
    <b-navbar-brand class="logos" to="/"></b-navbar-brand>

    <b-navbar-toggle target="sidebar-right">
      <template #default="{ expanded }">
        <b-icon v-if="expanded" icon="chevron-bar-up"></b-icon>
        <b-icon v-else icon="chevron-bar-down" @click="logOut"></b-icon>
      </template>
    </b-navbar-toggle>
  </b-navbar>
 </template>



<script>
export default {
    name: 'MainHeader',

    methods: {
      logOut(){
        localStorage.clear()
      }
    }
}
</script>


<style scoped>
  .logos {
    font-size: 1.4em;
    letter-spacing: 2px;
  }

</style>